import { IActionRequest } from 'types/Application.types'
import { ISale, ISaleSearch } from 'types/Sale.types'

export interface ISaleState {
  readonly sales?: IActionRequest<ISale[]>
  readonly saleSearch?: ISaleSearch
  readonly encalhes?: IActionRequest<ISale[]>
  readonly inventarios?: IActionRequest<ISale[]>
  readonly entradas?: IActionRequest<ISale[]>
  readonly saleEdit?: IActionRequest<ISale>
  readonly saleCharts?: IActionRequest<ISale[]>
  readonly saleNew?: IActionRequest<ISale>
}

export enum ESaleTypes {
  SALE_SEARCH_REQUEST = '@sale/REQUEST',
  SALE_SEARCH_RESPONSE = '@sale/RESPONSE',

  SALE_ENCALHE_REQUEST = '@sale/ENCALHE_REQUEST',
  SALE_ENCALHE_RESPONSE = '@sale/ENCALHE_RESPONSE',

  SALE_INVENTARIO_REQUEST = '@sale/INVENTARIO_REQUEST',
  SALE_INVENTARIO_RESPONSE = '@sale/INVENTARIO_RESPONSE',

  SALE_ENTRADA_REQUEST = '@sale/ENTRADA_REQUEST',
  SALE_ENTRADA_RESPONSE = '@sale/ENTRADA_RESPONSE',

  SALE_EDIT_SAVE = '@sale/EDIT_SAVE',
  SALE_EDIT_REQUEST = '@sale/EDIT_REQUEST',
  SALE_EDIT_RESPONSE = '@sale/EDIT_RESPONSE',

  SALE_NEW_REQUEST = '@sale/NEW_REQUEST',
  SALE_NEW_RESPONSE = '@sale/NEW_RESPONSE',

  SALE_ACTUAL_STOCK_REQUEST = '@sale/ACTUAL_STOCK_REQUEST',
  SALE_ACTUAL_STOCK_RESPONSE = '@sale/ACTUAL_STOCK_RESPONSE',

  SALE_CHART_REQUEST = '@sale/CHART_REQUEST',
  SALE_CHART_RESPONSE = '@sale/CHART_RESPONSE',
}
