import React, { useEffect, useMemo, useState } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Form, Input, Check, Select } from '@rocketseat/unform'

import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { cashLastRequest, cashLastResponse } from 'store/Cash/CashCreators'
import { selectCashLast } from 'store/Cash/CashSelectors'
import { paymentEditRequest, paymentEditResponse } from 'store/Payment/PaymentCreators'
import { selectPaymentEdit } from 'store/Payment/PaymentSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container6, List11 } from 'screens/Layout/styles'
import history from '~/services/history'

import { paymentTypeOptions } from './Payment.rules'

export default function PaymentEditScreen() {
  const dispatch = useDispatch()
  const paymentEdit = useSelector(selectPaymentEdit)
  const cashLast = useSelector(selectCashLast)

  const [payment, setPayment] = useState(paymentEdit?.data)
  const [newdate, setNewdate] = useState(paymentEdit?.data?.date)
  const dateFormatted = useMemo(() => {
    if (newdate) {
      return format(parseISO(newdate), "YYY-MM-dd'T'HH:mm", { locale: pt })
    }

    return format(new Date(), "YYY-MM-dd'T'HH:mm", { locale: pt })
  }, [newdate])

  async function handleSubmit(data) {
    dispatch(cashLastResponse({ type: EActionStatus.Waiting }))
    dispatch(cashLastRequest())
    setPayment(data)
  }

  useEffect(() => {
    if (cashLast?.status?.type === EActionStatus.Success) {
      dispatch(cashLastResponse({ type: EActionStatus.Waiting }))

      const last_cash = format(parseISO(cashLast?.data[0].date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt })
      const data_pagamento = format(parseISO(paymentEdit?.data?.date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt })
      if (last_cash > data_pagamento) {
        return toast.error('Caixa fechado')
      }
      if (last_cash > newdate) {
        return toast.error('Caixa fechado')
      }

      dispatch(
        paymentEditRequest({
          data: { ...payment, date: format(parseISO(newdate), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt }) },
          id: paymentEdit?.data?.id,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashLast?.status?.type])

  useEffect(() => {
    if (paymentEdit?.status.type === EActionStatus.Success) {
      history.push('/payments')

      dispatch(paymentEditResponse({ type: EActionStatus.Waiting }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentEdit?.status.type])

  return (
    <Container6>
      <Form initialData={paymentEdit?.data} onSubmit={handleSubmit}>
        <Link to="/payments">
          <MdChevronLeft size={30} />
        </Link>

        <span>Usuário</span>
        <Input name="seller" disabled placeholder="Usuário" />
        <span>Valor</span>
        <Input name="price" placeholder="Quantidade" />
        <span>Categoria</span>
        <Select name="category" placeholder="Forma de Pagamento" options={paymentTypeOptions} />
        <span>Descrição</span>
        <Input name="description" placeholder="Descrição" />

        <span>Data</span>
        <Input
          name="date"
          value={dateFormatted}
          onChange={e => setNewdate(e.target.value)}
          type="datetime-local"
          placeholder="Data do Pagamento"
        />

        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="5px" name="active" />
          </ul>
          <button type="submit">Atualizar Pagamento</button>
        </List11>
      </Form>
    </Container6>
  )
}
