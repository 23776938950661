import React, { useState, useEffect, useRef } from 'react'
import { FaBarcode } from 'react-icons/fa'
import { MdAddCircleOutline } from 'react-icons/md'
import { VscLoading } from 'react-icons/vsc'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Select, Check } from '@rocketseat/unform'
import { productNewSchema } from 'screens/Product/ProductScreen.rules'
import { categoryRequest } from 'store/Category/CategoryCreators'
import { selectCategoriesSelect } from 'store/Category/CategorySelectors'
import { productNewRequest } from 'store/Product/ProductCreators'
import { selectUserProfile } from 'store/User/UserSelectors'

import { Container6, List13, List10, List11 } from 'screens/Layout/styles'
import history from 'services/history'
import Scanner from 'components/BarCode/Scanner'
import Result from 'components/BarCode/Result'

export default function ProductNewScreen() {
  const dispatch = useDispatch()

  const profile = useSelector(selectUserProfile)
  const categoriesSelect = useSelector(selectCategoriesSelect)
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(categoryRequest())
  }, [dispatch, profile.company])

  async function handleSubmit(data) {
    if (!loading) {
      setLoading(true)
      dispatch(
        productNewRequest({
          ...data,
          description: 'Descrição',
          company: profile.company,
          enterprise: profile.enterprise,
        })
      )
      setLoading(false)
      history.push('/products')
    }
  }

  const [bar_code, setBar_code] = useState()
  const [BarCodeScanning, setBarCodeScanning] = useState(false)
  const [results, setResults] = useState([])
  const scannerRef = useRef(null)

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0])
        setBarCodeScanning(false)
        setShowBarcodeScanner(false)

        setResults([])
      }
    }
    loadProduct()
  }, [results])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner)
    setBarCodeScanning(!BarCodeScanning)
  }

  const [getcamid, setGetcamid] = useState()
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId)
        }
      })
    }
    loadCam_id()
  }, [profile.cam_id])

  return (
    <Container6>
      <Form schema={productNewSchema} onSubmit={handleSubmit}>
        <List13>
          <Input name="bar_code" value={bar_code} onChange={e => setBar_code(e.target.value)} placeholder="Código" />
          <FaBarcode size={30} onClick={() => handleBarcode()} color="#FFF" />
        </List13>

        {!showBarcodeScanner ? (
          <></>
        ) : (
          <div>
            <ul className="results">
              {results.map(result => result.codeResult && <Result key={result.codeResult.code} result={result} />)}
            </ul>
            <div ref={scannerRef} style={{ position: 'relative' }}>
              <canvas
                className="drawingBuffer"
                style={{ position: 'absolute', top: '0px', border: '3px solid green' }}
                width="480"
                height="350"
              />

              {BarCodeScanning ? (
                <Scanner
                  aaaa={getcamid}
                  scannerRef={scannerRef}
                  teste={results}
                  onDetected={result => setResults([...results, result])}
                />
              ) : null}
            </div>
          </div>
        )}

        <Input name="name" placeholder="Nome do Produto" />

        <List13>
          <Select name="category" placeholder="Categoria do Produto" options={categoriesSelect} />
          {profile.level ? null : (
            <MdAddCircleOutline size={30} color="#fff" onClick={() => history.push('/newcategory')} />
          )}
        </List13>

        <List10>
          <Input name="price" placeholder="Preço do Produto" />
          {profile.level ? <></> : <Input name="average_cost" placeholder="Custo do Produto" />}
        </List10>
        <span>Estoque</span>
        <List11>
          <ul>{profile.level ? <></> : <Check name="stockpile" />}</ul>
          <Input name="stock" placeholder="Quantidade" />
        </List11>

        {loading ? <VscLoading /> : <button type="submit">Adicionar Produto</button>}
      </Form>
    </Container6>
  )
}
