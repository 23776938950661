import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectProductState = (state: ReturnType<typeof combineReducers>) => {
  return state.product
}

export const selectProductSearch = createSelector([selectProductState], ({ productSearch }) => productSearch)

export const selectProducts = createSelector([selectProductState], ({ products }) => products)

export const selectProductEdit = createSelector([selectProductState], ({ productEdit }) => productEdit)

export const selectProductStock = createSelector([selectProductState], ({ productStock }) => productStock)

export const selectProductEditStockStatus = createSelector(
  [selectProductState],
  ({ productEditStockStatus }) => productEditStockStatus
)
