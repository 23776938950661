import { IActionRequest } from 'types/Application.types'
import { IFile } from 'types/File.types'

export interface IFileState {
  readonly fileNew?: IActionRequest<IFile>
}

export enum EFileTypes {
  FILE_NEW_REQUEST = '@file/NEW_REQUEST',
  FILE_NEW_RESPONSE = '@file/NEW_RESPONSE',
}
