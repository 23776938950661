import produce from 'immer'
import { Reducer } from 'redux'
import { ESaleTypes, ISaleState } from 'store/Sale/SaleCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { ISale, ISaleSearch } from 'types/Sale.types'

const SALE_INITIAL_STATE: ISaleState = {}

const SaleReducer: Reducer<ISaleState> = (state = SALE_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ESaleTypes.SALE_SEARCH_REQUEST: {
        const {
          payload: { saleSearch },
        } = action as ReturnType<typeof saleSearchRequest>

        draft.saleSearch = saleSearch

        draft.sales = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case ESaleTypes.SALE_SEARCH_RESPONSE: {
        const {
          payload: { actionStatus, sales },
        } = action as ReturnType<typeof saleSearchResponse>

        draft.sales = {
          status: actionStatus,
          data: sales,
        }

        return draft
      }

      case ESaleTypes.SALE_ENCALHE_REQUEST: {
        draft.encalhes = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case ESaleTypes.SALE_ENCALHE_RESPONSE: {
        const {
          payload: { actionStatus, saleEncalhes },
        } = action as ReturnType<typeof saleEncalhesResponse>

        draft.encalhes = {
          status: actionStatus,
          data: saleEncalhes,
        }

        return draft
      }

      case ESaleTypes.SALE_INVENTARIO_REQUEST: {
        draft.inventarios = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case ESaleTypes.SALE_INVENTARIO_RESPONSE: {
        const {
          payload: { actionStatus, saleInventarios },
        } = action as ReturnType<typeof saleInventariosResponse>

        draft.inventarios = {
          status: actionStatus,
          data: saleInventarios,
        }

        return draft
      }

      case ESaleTypes.SALE_CHART_REQUEST: {
        draft.saleCharts = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case ESaleTypes.SALE_CHART_RESPONSE: {
        const {
          payload: { actionStatus, saleChart },
        } = action as ReturnType<typeof saleChartResponse>

        draft.saleCharts = {
          status: actionStatus,
          data: saleChart,
        }

        return draft
      }

      case ESaleTypes.SALE_ENTRADA_REQUEST: {
        draft.entradas = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case ESaleTypes.SALE_ENTRADA_RESPONSE: {
        const {
          payload: { actionStatus, saleEntradas },
        } = action as ReturnType<typeof saleEntradasResponse>

        draft.entradas = {
          status: actionStatus,
          data: saleEntradas,
        }

        return draft
      }

      case ESaleTypes.SALE_EDIT_SAVE: {
        const {
          payload: { saleEdit },
        } = action as ReturnType<typeof saleEditSave>
        draft.saleEdit = {
          status: { type: EActionStatus.Waiting },
          data: saleEdit,
        }

        return draft
      }

      case ESaleTypes.SALE_EDIT_REQUEST: {
        const {
          payload: { saleEdit },
        } = action as ReturnType<typeof saleEditRequest>

        draft.saleEdit = {
          status: { type: EActionStatus.Busy },
          data: saleEdit,
        }

        return draft
      }

      case ESaleTypes.SALE_EDIT_RESPONSE: {
        const {
          payload: { actionStatus, saleEdit },
        } = action as ReturnType<typeof saleEditResponse>

        draft.saleEdit = {
          status: actionStatus,
          data: saleEdit,
        }

        return draft
      }

      case ESaleTypes.SALE_NEW_REQUEST: {
        const {
          payload: { saleNew },
        } = action as ReturnType<typeof saleNewRequest>

        draft.saleNew = {
          status: { type: EActionStatus.Busy },
          data: saleNew,
        }

        return draft
      }

      case ESaleTypes.SALE_NEW_RESPONSE: {
        const {
          payload: { actionStatus, saleNew },
        } = action as ReturnType<typeof saleNewResponse>

        draft.saleNew = {
          status: actionStatus,
          data: saleNew,
        }

        return draft
      }

      case ESaleTypes.SALE_ACTUAL_STOCK_REQUEST: {
        return draft
      }

      case ESaleTypes.SALE_ACTUAL_STOCK_RESPONSE: {
        const {
          payload: { stock, productId },
        } = action as ReturnType<typeof saleActualStockResponse>

        draft.saleEdit = {
          ...draft.saleEdit!,
          data: {
            ...draft.saleEdit?.data!,
            atual_stock: stock,
            productId,
          },
        }

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default SaleReducer

export const saleSearchRequest = (saleSearch: ISaleSearch) => ({
  type: ESaleTypes.SALE_SEARCH_REQUEST,
  payload: { saleSearch },
})

export const saleSearchResponse = (actionStatus: IActionStatus, sales?: ISale[]) => ({
  type: ESaleTypes.SALE_SEARCH_RESPONSE,
  payload: { actionStatus, sales },
})

export const saleEncalhesRequest = (startDate: Date, endDate: Date) => ({
  type: ESaleTypes.SALE_ENCALHE_REQUEST,
  payload: { startDate, endDate },
})

export const saleEncalhesResponse = (actionStatus: IActionStatus, saleEncalhes?: ISale[]) => ({
  type: ESaleTypes.SALE_ENCALHE_RESPONSE,
  payload: { actionStatus, saleEncalhes },
})

export const saleInventariosRequest = (startDate: Date, endDate: Date) => ({
  type: ESaleTypes.SALE_INVENTARIO_REQUEST,
  payload: { startDate, endDate },
})

export const saleInventariosResponse = (actionStatus: IActionStatus, saleInventarios?: ISale[]) => ({
  type: ESaleTypes.SALE_INVENTARIO_RESPONSE,
  payload: { actionStatus, saleInventarios },
})

export const saleEntradasRequest = (startDate: Date, endDate: Date) => ({
  type: ESaleTypes.SALE_ENTRADA_REQUEST,
  payload: { startDate, endDate },
})

export const saleEntradasResponse = (actionStatus: IActionStatus, saleEntradas?: ISale[]) => ({
  type: ESaleTypes.SALE_ENTRADA_RESPONSE,
  payload: { actionStatus, saleEntradas },
})

export const saleEditSave = (saleEdit: ISale) => ({
  type: ESaleTypes.SALE_EDIT_SAVE,
  payload: { saleEdit },
})

export const saleEditRequest = (saleEdit: ISale) => ({
  type: ESaleTypes.SALE_EDIT_REQUEST,
  payload: { saleEdit },
})

export const saleEditResponse = (actionStatus: IActionStatus, saleEdit?: ISale) => ({
  type: ESaleTypes.SALE_EDIT_RESPONSE,
  payload: { actionStatus, saleEdit },
})

export const saleNewRequest = (saleNew: ISale) => ({
  type: ESaleTypes.SALE_NEW_REQUEST,
  payload: { saleNew },
})

export const saleNewResponse = (actionStatus: IActionStatus, saleNew?: ISale) => ({
  type: ESaleTypes.SALE_NEW_RESPONSE,
  payload: { actionStatus, saleNew },
})

export const saleActualStockRequest = (bar_code: string) => ({
  type: ESaleTypes.SALE_ACTUAL_STOCK_REQUEST,
  payload: { bar_code },
})

export const saleActualStockResponse = (stock: number, productId: number) => ({
  type: ESaleTypes.SALE_ACTUAL_STOCK_RESPONSE,
  payload: { stock, productId },
})

export const saleChartRequest = (startDate: Date, endDate: Date) => ({
  type: ESaleTypes.SALE_CHART_REQUEST,
  payload: { startDate, endDate },
})

export const saleChartResponse = (actionStatus: IActionStatus, saleChart?: ISale[]) => ({
  type: ESaleTypes.SALE_CHART_RESPONSE,
  payload: { actionStatus, saleChart },
})
