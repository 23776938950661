import api from 'services/api'
import { IUserSignIn } from 'types/User.types'
import { IUserProfile } from 'types/User.types'

export class UserService {
  postLogin = (userSignIn: IUserSignIn) => api.post<IUserSignInResponse>('sessions', userSignIn)

  postProfileUpdate = () => api.post<IUserSignInResponse>('sessionsupdate')

  postUser = (userProfile: Omit<IUserProfile, 'id'>) => api.post<IUserProfile>('users', userProfile)

  putUser = (userProfile: IUserProfile) => api.put<IUserProfile>('users', userProfile)

  getUsers = () => api.get<IUserProfile[]>('users')
}

export interface IUserSignInResponse {
  token: string
  tokenexpires: number
  user: IUserProfile
}
