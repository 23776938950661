import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { productStockRequest } from 'store/Product/ProductCreators'
import { selectProductStock } from 'store/Product/ProductSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container, List14, List20 } from 'screens/Layout/styles'
import { formatPrice } from '~/util/format'

export default function StockScreen() {
  const dispatch = useDispatch()
  const [schedule, setSchedule] = useState([])

  const profile = useSelector(selectUserProfile)

  const productStock = useSelector(selectProductStock)

  useEffect(() => {
    if (productStock?.status.type === EActionStatus.Success) {
      const data = productStock?.data.map(bb => ({
        ...bb,
        total: bb.stock * bb.average_cost,
      }))
      const uniqueCategories = array => [...new Set(array.map(item => item.category))]
      const copy = []
      uniqueCategories(data).forEach((element, id) => {
        copy.push({
          id,
          category: element,
          total: data
            .filter(item => item.category === element)
            .map(item => item.total)
            .reduce((a, b) => a + b),
        })
      })
      setSchedule(copy.sort((a, b) => (a.total > b.total ? -1 : 1)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, profile?.company, productStock?.status.type])

  useEffect(() => dispatch(productStockRequest()), [dispatch])

  return (
    <Container>
      <List20>
        {schedule.map(stock => (
          <List14 key={String(stock.id)}>
            <span>{stock.category} </span>
            <aside>
              <span>{formatPrice(stock.total)}</span>
            </aside>
          </List14>
        ))}
      </List20>
    </Container>
  )
}
