import { IActionRequest, IActionStatus } from 'types/Application.types'
import { IDebt, IEnterprise } from 'types/Enterprise.types'

export interface IEnterpriseState {
  readonly enterprises?: IEnterprise[]
  readonly debts?: IActionRequest<IDebt[]>
  readonly enterpriseNewStatus?: IActionStatus
}

export enum EEnterpriseTypes {
  ENTERPRISE_ENTERPRISES_REQUEST = '@enterprise/ENTERPRISES_REQUEST',
  ENTERPRISE_ENTERPRISES_RESPONSE = '@enterprise/ENTERPRISES_RESPONSE',

  ENTERPRISE_DEBTS_REQUEST = '@enterprise/DEBTS_REQUEST',
  ENTERPRISE_DEBTS_RESPONSE = '@enterprise/DEBTS_RESPONSE',

  ENTERPRISE_NEW_REQUEST = '@enterprise/NEW_REQUEST',
  ENTERPRISE_NEW_RESPONSE = '@enterprise/NEW_RESPONSE',
}
