import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import history from 'services/history'
import { IProductsResponse, ProductService } from 'services/ProductService'
import {
  productSearchRequest,
  productSearchResponse,
  productEditRequest,
  productEditResponse,
  productNewRequest,
  productNewResponse,
  productStockResponse,
  productStockRequest,
  productEditStockRequest,
  productEditStockResponse,
} from 'store/Product/ProductCreators'
import { EProductTypes } from 'store/Product/ProductCreators.types'
import { saleActualStockRequest, saleActualStockResponse } from 'store/Sale/SaleCreators'
import { ESaleTypes } from 'store/Sale/SaleCreators.types'
import { EActionStatus } from 'types/Application.types'
import { IProduct, IProductStock } from 'types/Product.types'

export function* productSearchRequestSaga(action: ReturnType<typeof productSearchRequest>) {
  try {
    const { productSearch } = action.payload
    const { getProducts, getProducts3 } = new ProductService()

    let response: AxiosResponse<IProductsResponse>

    if (productSearch.bar_code && productSearch.bar_code.length >= 2) {
      response = yield getProducts3(productSearch)
    } else {
      response = yield getProducts({
        itens_por_pagina: productSearch.itens_por_pagina,
        page: productSearch.page,
        order: productSearch.order,
        order2: productSearch.order2,
        direction: productSearch.direction,
        category: productSearch.category,
      })
    }

    yield put(productSearchResponse(response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('console.log')
    }
  }
}

export function* productEditRequestSaga(action: ReturnType<typeof productEditRequest>) {
  try {
    const { productEdit } = action.payload
    const { putProduct3 } = new ProductService()

    const response: AxiosResponse<IProduct> = yield putProduct3(productEdit, productEdit.id)

    yield put(productEditResponse(response.data))

    toast.success('Produto atualizado com sucesso')
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('Falha ao atualizar Produto')
    }
  }
}

export function* productNewRequestSaga(action: ReturnType<typeof productNewRequest>) {
  try {
    const { productNew } = action.payload
    const { postProduct } = new ProductService()

    const response: AxiosResponse<IProduct> = yield postProduct(productNew)

    yield put(productNewResponse(response.data))

    toast.success('Produto cadastrado com sucesso!')
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('Falha ao cadastrar o produto')
    }
  }
}

export function* productStockRequestSaga(_action: ReturnType<typeof productStockRequest>) {
  try {
    const { getProductsStock } = new ProductService()

    const response: AxiosResponse<IProductStock[]> = yield getProductsStock()

    yield put(productStockResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(productStockResponse({ type: EActionStatus.Failure }, []))
      toast.error('Falha ao carregar estoque')
    }
  }
}

export function* productActualStockRequestSaga(action: ReturnType<typeof saleActualStockRequest>) {
  try {
    const { bar_code } = action.payload

    const { getProductStock } = new ProductService()

    const response: AxiosResponse<IProductsResponse> = yield getProductStock(bar_code)

    yield put(saleActualStockResponse(response.data.products[0].stock, response.data.products[0].id))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleActualStockResponse(0, 0))
      toast.error('Falha ao carregar estoque atual')
    }
  }
}

export function* productEditStockRequestSaga(action: ReturnType<typeof productEditStockRequest>) {
  try {
    const { id, subtract_stock } = action.payload
    const { putProductStock } = new ProductService()

    yield putProductStock(id, subtract_stock)

    yield put(productEditStockResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(productEditStockResponse({ type: EActionStatus.Failure }))
      toast.error('Falha ao atualizar estoque')
    }
  }
}

export default function* () {
  yield takeLatest(EProductTypes.PRODUCT_SEARCH_REQUEST, productSearchRequestSaga)
  yield takeLatest(EProductTypes.PRODUCT_EDIT_REQUEST, productEditRequestSaga)
  yield takeLatest(EProductTypes.PRODUCT_NEW_REQUEST, productNewRequestSaga)
  yield takeLatest(EProductTypes.PRODUCT_STOCK_REQUEST, productStockRequestSaga)
  yield takeLatest(EProductTypes.PRODUCT_EDIT_STOCK_REQUEST, productEditStockRequestSaga)
  yield takeLatest(ESaleTypes.SALE_ACTUAL_STOCK_REQUEST, productActualStockRequestSaga)
}
