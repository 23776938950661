import React, { useState, useMemo, useEffect } from 'react'

import { Grid, Typography, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

// import { ISaleNew } from 'types/Sale.types'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

export interface ISaleNewPgtoProps {
  basket: any[]
  handleSend: (pgto: string, pgto2: string, pgtoValue: number, pgto2Value: number, troco: number) => void
  show: boolean
}

export interface IOption {
  id: string
  title: string
}

export const SaleNewPgtoComponent: React.FC<ISaleNewPgtoProps> = ({ handleSend, basket, show = false }) => {
  const SaleNewListComponentStyle = makeStyles({
    OutlinedTextField: {
      backgroundColor: '#F5F5F5 !important',
      borderWidth: 0,
      outline: 'none',
      borderColor: 'white !important',
      border: 'none !important',
      padding: 0,
      margin: '0  !important',
      marginBottom: 0,
      fontSize: 12,
      color: 'black !important',
      textDecorationColor: 'black',
      '&::placeholder': {
        color: 'black !important',
      },
    },
    textField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
    },
    descField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
    },
    qtdField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
      margin: '0 0 10px 0 !important',
    },
    qtdInput: {
      margin: '0  !important',
      padding: '0  !important',
      color: 'black !important',
      textAlign: 'center',
      minHeight: '30px !important',
      minWidth: '50px !important',
    },
  })
  const styles = SaleNewListComponentStyle()

  const [options2, setOptions2] = useState<IOption[]>()
  const [pgto, setPgto] = useState('')
  const [pgto2, setPgto2] = useState('')
  const [pgtoValue, setPgtoValue] = useState(0)
  const [pgto2Value, setPgto2Value] = useState(0)
  const [loading, setLoading] = useState(false)
  const [troco, setTroco] = useState(0)

  const options = useMemo(
    () => [
      { id: 'Dinheiro', title: 'Dinheiro' },
      { id: 'Débito', title: 'Débito' },
      { id: 'Crédito', title: 'Crédito' },
      { id: 'Pix', title: 'Pix' },
      { id: 'Inventário', title: 'Inventário' },
      { id: 'Entrada', title: 'Entrada' },
      { id: 'Encalhe', title: 'Encalhe' },
    ],
    []
  )

  const isPayable: boolean = useMemo(
    () => pgto === 'Dinheiro' || pgto === 'Crédito' || pgto === 'Débito' || pgto === 'Pix',
    [pgto]
  )
  const totalCompra = useMemo(() => basket?.reduce((result, item) => result + item.total_price, 0), [basket])

  useEffect(() => {
    if (pgto === 'Dinheiro' || pgto === 'Crédito' || pgto === 'Débito' || pgto === 'Pix') {
      setOptions2(
        options.filter(({ id }) => id !== pgto && id !== 'Encalhe' && id !== 'Entrada' && id !== 'Inventário')
      )
    }
  }, [options, pgto])

  useEffect(() => {
    setTroco(
      Number.parseFloat(String(pgtoValue)) +
        Number.parseFloat(String(pgto2Value)) -
        Number.parseFloat(String(totalCompra))
    )
  }, [pgtoValue, pgto2Value, totalCompra])

  return show ? (
    <></>
  ) : (
    <Grid container display="flex">
      <Grid item display="flex" lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Pagamento</InputLabel>
            <Select
              name="pgto"
              value={pgto}
              onChange={(event: SelectChangeEvent<string>) => {
                setPgto(event.target.value)
                setPgto2('')
              }}>
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <>
            {isPayable && (
              <TextField
                className={styles.textField}
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPgtoValue(event.target.value !== '' ? Number(event.target.value) : 0)
                }}
                name="pgtoValue"
              />
            )}
          </>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <>
            {pgtoValue > 0 && !basket[1] && isPayable && (
              <>
                <Typography>Troco</Typography>
                <Typography>R$ {troco.toFixed(2)}</Typography>
              </>
            )}
          </>{' '}
        </Grid>
      </Grid>

      <Grid item display="flex" lg={12} md={12} sm={12} xs={12}>
        {!basket[0] || basket[1] || pgtoValue <= 0 ? (
          <></>
        ) : (
          isPayable && (
            <>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Pagamento</InputLabel>
                  <Select
                    name="pgto2"
                    placeholder="Pagamento"
                    value={pgto2}
                    onChange={(event: SelectChangeEvent<string>) => setPgto2(event.target.value)}>
                    {options2?.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <TextField
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPgto2Value(event.target.value !== '' ? Number(event.target.value) : 0)
                  }}
                  name="pgto2Value"
                />
              </Grid>
            </>
          )
        )}
      </Grid>

      <Grid item>
        {!pgto ? null : (
          <>
            {!loading ? (
              <button
                onClick={() => {
                  setLoading(true)
                  handleSend(pgto, pgto2, pgtoValue, pgto2Value, troco)
                }}
                type="submit">
                Finalizar Venda
              </button>
            ) : (
              <button type="submit">Carregando...</button>
            )}

            <button type="button" onClick={() => window.print()}>
              Imprimir
            </button>
          </>
        )}
      </Grid>
    </Grid>
  )
}
