import React from 'react'

import pix from '~/assets/pix.jpg'
import { Container, Container6 } from 'screens/Layout/styles'

export default function DepositScreen() {
  return (
    <Container>
      <Container6>
        <strong>Faça um PIX para a conta abaixo:</strong>
        <img alt="PIX" width="300px" src={pix} />
      </Container6>
    </Container>
  )
}
