import { persistStore } from 'redux-persist'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import persistReducers from 'store/persistReducers.js'

import rootReducer from 'store/ReducerConfig'
import SagaConfig from 'store/SagaConfig'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistReducers(rootReducer), applyMiddleware(sagaMiddleware))
const persistor = persistStore(store)

sagaMiddleware.run(SagaConfig)

export { store, persistor }
