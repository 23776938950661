import React, { useState, useMemo, useEffect } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { format, subDays, addDays, startOfDay, endOfDay, parseISO, isAfter } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { saleEncalhesRequest } from 'store/Sale/SaleCreators'
import { selectEncalhes } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'

import { Container, List3 } from 'screens/Layout/styles'

export default function EncalheScreen() {
  const dispatch = useDispatch()
  const encalhes = useSelector(selectEncalhes)
  const [date, setDate] = useState(new Date())
  const profile = useSelector(selectUserProfile)

  const [showright, setShowright] = useState(false)

  const dateFormatted = useMemo(() => format(date, "d 'de' MMMM", { locale: pt }), [date])

  useEffect(() => {
    dispatch(saleEncalhesRequest(startOfDay(date), endOfDay(date)))
    //eslint-disable-next-line
  }, [dispatch, date, dateFormatted, profile?.company])

  function handlePrevDay() {
    setDate(subDays(date, 1))
    if (isAfter(new Date(), subDays(date, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }
  function handleNextDay() {
    setDate(addDays(date, 1))
    if (isAfter(new Date(), addDays(date, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }

  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

  return (
    <Container>
      <header>
        <>
          <button type="button" onClick={handlePrevDay}>
            <MdChevronLeft size={30} color="#ffff" />
          </button>
          <strong>
            {dateFormatted} ({days[date.getDay()]})
          </strong>
          {!showright ? null : (
            <button type="button" onClick={handleNextDay}>
              <MdChevronRight size={30} color="#ffff" />
            </button>
          )}
        </>
      </header>

      <></>

      <ul>
        {encalhes?.data?.map(encalhe => (
          <List3 key={String(encalhe.id)} noactive={!encalhe.active}>
            <span>
              {format(parseISO(encalhe.date), "dd'/'MM'-'HH:mm", { locale: pt })} {encalhe.name} {encalhe.pgto}{' '}
              {encalhe.note}
            </span>

            <aside3>
              <ul>
                <span>{encalhe.qty}</span>
              </ul>
            </aside3>
          </List3>
        ))}
      </ul>
      <ul />
    </Container>
  )
}
