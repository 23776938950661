import * as Yup from 'yup'

export const paymentNewSchema = Yup.object().shape({
  price: Yup.number()
    .required('O valor é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O valor deve ser positivo'),
  category: Yup.string().required('A categoria é obrigatório'),
  description: Yup.string().required('A descrição é obrigatória'),
})

export const paymentTypeOptions = [
  { id: 'Fornecedor', title: 'Fornecedor' },
  { id: 'Funcionário', title: 'Funcionário' },
  { id: 'Diversos', title: 'Diversos' },
]
