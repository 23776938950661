import React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import { List5 } from 'screens/Layout/styles'

export interface IPageSelectorProps {
  handlereturnpage: () => void
  handleaddpage: () => void
  page: number
  totalpages: number
  show: boolean
}

export const PageSelectorComponent: React.FC<IPageSelectorProps> = ({
  page,
  totalpages,
  handlereturnpage,
  handleaddpage,
  show = true,
}) => {
  return (
    <>
      {show && (
        <List5>
          {page === 1 ? null : (
            <button type="button" onClick={() => handlereturnpage()}>
              <MdChevronLeft size={30} color="#fff" />
            </button>
          )}
          <strong>
            Página {page} / {totalpages}
          </strong>
          {page >= totalpages ? null : (
            <button type="button" onClick={() => handleaddpage()}>
              <MdChevronRight size={30} color="#fff" />
            </button>
          )}
        </List5>
      )}
    </>
  )
}
