import React, { useEffect, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'

import { paymentRequest } from 'store/Payment/PaymentCreators'
import { selectPayments } from 'store/Payment/PaymentSelectors'
import { productSearchRequest } from 'store/Product/ProductCreators'
import { selectProducts } from 'store/Product/ProductSelectors'
import { saleSearchRequest } from 'store/Sale/SaleCreators'
import { selectSales } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container6 } from 'screens/Layout/styles'

export default function DownloadScreen() {
  const dispatch = useDispatch()
  const profile = useSelector(selectUserProfile)
  const products = useSelector(selectProducts)
  const sales = useSelector(selectSales)
  const payments = useSelector(selectPayments)

  useEffect(() => {
    const date1 = '01/01/2000'
    const date2 = new Date()

    dispatch(
      productSearchRequest({
        itens_por_pagina: 1000000,
      })
    )
    dispatch(saleSearchRequest({ date1, date2 }))
    dispatch(paymentRequest(date1, date2))
  }, [dispatch])

  const productsCSV = useMemo(() => {
    const convertida = products?.map(item => [
      item.id,
      item.last_sale,
      item.bar_code,
      item.name,
      item.last_cost,
      item.average_cost,
      item.description,
      item.category,
      item.price,
      item.active,
      item.stock,
      item.stockpile,
      item.company,
    ])
    const newStatuses = [
      [
        'id',
        'last_sale',
        'bar_code',
        'name',
        'last_cost',
        'average_cost',
        'description',
        'category',
        'price',
        'active',
        'stock',
        'stockpile',
        'company',
      ],
    ].concat(convertida)
    return newStatuses
  }, [products])

  const salesCSV = useMemo(() => {
    if (sales?.status?.type === EActionStatus.Success) {
      const convertida = sales?.data?.map(item => [
        item.id,
        item.discount,
        item.seller,
        item.date,
        item.active,
        item.note,
        item.bar_code,
        item.name,
        item.description,
        item.pgto,
        item.category,
        item.price,
        item.average_cost,
        item.qty,
        item.total_price,
        item.created_at,
        item.company,
      ])
      const newStatuses = [
        [
          'id',
          'discount',
          'seller',
          'date',
          'active',
          'note',
          'bar_code',
          'name',
          'description',
          'pgto',
          'category',
          'price',
          'average_cost',
          'qty',
          'total_price',
          'created_at',
          'company',
        ],
      ].concat(convertida)
      return newStatuses
    }
  }, [sales])

  const paymentsCSV = useMemo(() => {
    if (payments?.status?.type === EActionStatus.Success) {
      const convertida = payments?.data?.map(item => [
        item.id,
        item.active,
        item.description,
        item.category,
        item.price,
        item.created_at,
        item.company,
        item.enterprise,
        item.date,
        item.seller,
      ])

      const newStatuses = [
        ['id', 'active', 'description', 'category', 'price', 'created_at', 'company', 'enterprise', 'date', 'seller'],
      ].concat(convertida)
      return newStatuses
    }
  }, [payments])

  return (
    <Container6>
      {profile?.level ? (
        <></>
      ) : (
        <>
          {!sales?.data ? <></> : <CSVLink data={salesCSV}>{`Vendas ${salesCSV.length}`}</CSVLink>}
          {!products ? <></> : <CSVLink data={productsCSV}>{`Produtos ${productsCSV.length}`}</CSVLink>}
          {!payments?.data ? <></> : <CSVLink data={paymentsCSV}>{`Pagamentos ${paymentsCSV.length}`}</CSVLink>}
        </>
      )}
    </Container6>
  )
}
