import React from 'react'
import PropTypes from 'prop-types'

import HeaderComponent from '~/components/Header/HeaderComponent'
import Header2 from '~/components/Header2'
import Header3 from 'components/Header3'
import { Wrapper, WrapperAuth, ContentAuth } from './styles'
import { useSelector } from 'react-redux'
import { selectUserProfile } from 'store/User/UserSelectors'

export default function LayoutScreen({ layout, children }) {
  const userProfile = useSelector(selectUserProfile)

  return (
    <>
      {layout === 'private' && (
        <Wrapper>
          {userProfile.username !== 'pedro@teste' ? null : <Header2 />}
          {userProfile.header === 'below' ? null : <HeaderComponent />}
          <Header3 title={children.props.location.pathname} />
          {children}
          {userProfile.header !== 'below' ? null : <HeaderComponent />}
        </Wrapper>
      )}
      {layout === 'public' && (
        <WrapperAuth>
          <ContentAuth>{children}</ContentAuth>
        </WrapperAuth>
      )}
    </>
  )
}

LayoutScreen.propTypes = {
  children: PropTypes.element.isRequired,
}
