import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from '@rocketseat/unform'
import { Form } from '@unform/web'

import { userSignUpSchema } from 'screens/User/UserSignInScreen.rules'
import { enterpriseEnterprisesRequest } from 'store/Enterprise/EnterpriseCreators'
import { selectEnterpriseCompaniesSelect } from 'store/Enterprise/EnterpriseSelectors'
import { userProfileCreateRequest } from 'store/User/UserCreators'
import { selectUserProfile } from 'store/User/UserSelectors'

import Input from '~/components/Input'
import { Container6, List10 } from 'screens/Layout/styles'

export default function SignUpScreen() {
  const dispatch = useDispatch()
  const profile = useSelector(selectUserProfile)
  const enterpriseCompaniesSelect = useSelector(selectEnterpriseCompaniesSelect)
  const [companyvalue, setCompanyvalue] = useState(profile?.company)
  const [user, setUser] = useState('')

  async function handleSubmit(data) {
    dispatch(
      userProfileCreateRequest({
        ...data,
        company: companyvalue,
        enterprise: profile.enterprise,
        username: `${data.username}@${profile.enterprise}`,
      })
    )
  }

  useEffect(() => {
    dispatch(enterpriseEnterprisesRequest())
  }, [dispatch, profile])

  const formRef = useRef(null)
  useEffect(() => {
    formRef.current.setData({
      user: '',
    })
  }, [profile])

  return (
    <Container6>
      <Form ref={formRef} schema={userSignUpSchema} onSubmit={handleSubmit}>
        <Input autoComplete="off" name="name" placeholder="Nome" />
        <Select
          name="company"
          placeholder="Empresa"
          onChange={e => setCompanyvalue(e.target.value)}
          value={companyvalue}
          options={enterpriseCompaniesSelect}
        />
        <List10>
          <Input name="username" value={user} onChange={e => setUser(e.target.value)} placeholder="Usuário" />
          <Input
            autoComplete="off"
            name="enterprise"
            disabled
            value={`@${profile?.enterprise}`}
            placeholder="@Empresa"
          />
        </List10>
        <Input autoComplete="off" name="password" type="password" placeholder="Senha" />
        <button type="submit"> Criar Vendedor</button>
      </Form>
    </Container6>
  )
}
