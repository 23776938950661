import api from 'services/api'
import { ISale, ISaleSearch } from 'types/Sale.types'

export class SaleService {
  getSales = (saleSearch: ISaleSearch) =>
    api.get<ISale[]>('sales', {
      params: {
        date1: saleSearch?.date1,
        date2: saleSearch?.date2,
        active: saleSearch?.active,
        ordercreate: saleSearch?.ordercreate,
      },
    })

  getSales3 = (saleSearch: ISaleSearch) => api.get<ISale[]>('sales3', { params: { bar_code: saleSearch?.bar_code } })

  putSale = (saleEdit: ISale, id: number) => api.put('sales', { data: saleEdit, id })

  postSale = (newSale: ISale) => api.post('sales', newSale)

  getEncalhes = (startDate: Date, endDate: Date) =>
    api.get<ISale[]>('encalhe', {
      params: { date1: startDate, date2: endDate, active: true, ordercreate: 'DESC' },
    })

  getInventarios = (startDate: Date, endDate: Date) =>
    api.get<ISale[]>('inventario', {
      params: { date1: startDate, date2: endDate, active: true, ordercreate: 'DESC' },
    })

  getEntradas = (startDate: Date, endDate: Date) =>
    api.get<ISale[]>('entrada', {
      params: { date1: startDate, date2: endDate, active: true, ordercreate: 'DESC' },
    })

  getChart = (startDate: Date, endDate: Date) =>
    api.get<ISale[]>('charts', {
      params: { date1: startDate, date2: endDate, company: '' },
    })
}
