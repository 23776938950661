import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectCashState = (state: ReturnType<typeof combineReducers>) => {
  return state.cash
}

export const selectCash = createSelector([selectCashState], ({ cash }) => cash || [])

export const selectCashLast = createSelector([selectCashState], ({ cashLast }) => cashLast || [])

export const selectCashNewStatus = createSelector([selectCashState], ({ cashNewStatus }) => cashNewStatus || [])
