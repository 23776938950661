import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { FaBarcode } from 'react-icons/fa'
import { batch, useDispatch, useSelector } from 'react-redux'

import { Form, Input, Select } from '@rocketseat/unform'

import { PageSelectorComponent } from 'components2/PageSelector/PageSelector'
import { ProductListComponent } from 'components2/ProductList/ProductListComponent'
// import debounce from 'lodash.debounce'
import { Container, Grid, List25 } from 'screens/Layout/styles'
import { orderOptions, defaultProductSearch } from 'screens/Product/ProductScreen.rules'
import history from 'services/history'
import { categoryRequest } from 'store/Category/CategoryCreators'
import { selectCategoriesSelect } from 'store/Category/CategorySelectors'
import { productEditSave, productSearchRequest } from 'store/Product/ProductCreators'
import { selectProducts, selectProductSearch } from 'store/Product/ProductSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EOrderDirection } from 'types/Application.types'
import { BarcodeScannerComponentTeste } from 'components2/BarCodeScanner/BarCodeScannerTeste'

export default function ProductScreen() {
  const dispatch = useDispatch()

  const products = useSelector(selectProducts)
  const categoriesSelect = useSelector(selectCategoriesSelect)
  const productSearch = useSelector(selectProductSearch) ?? defaultProductSearch
  const profile = useSelector(selectUserProfile)

  const [codeeeee, setCodeeeee] = useState('')
  const [bar_code, setBar_code] = useState(productSearch.bar_code)

  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false)

  const options4 = useMemo(
    () => [
      { id: 'Filtros', title: 'Filtros' },
      { id: 'Ativos', title: 'Ativos' },
      { id: 'Inativos', title: 'Inativos' },
    ],
    []
  )

  const options3 = useMemo(() => [...options4, ...categoriesSelect], [categoriesSelect, options4])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner)
    setBarCodeScanning(!BarCodeScanning)
  }

  const [BarCodeScanning, setBarCodeScanning] = useState(false)
  const [results, setResults] = useState([])
  const [getcamid, setGetcamid] = useState()
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId)
        }
      })
    }
    loadCam_id()
  }, [profile.cam_id])

  function handleEdit(product) {
    dispatch(productEditSave(product))
    history.push('/editproduct')
  }

  const handlesearch = useCallback(
    e => {
      dispatch(
        productSearchRequest({
          ...productSearch,
          page: 1,
          bar_code: e,
          bar_code2: e,
        })
      )
    },
    [dispatch, productSearch]
  )

  // const debouncedChangeHandler = useCallback(() => {
  //   debounce(handlesearch, 300)
  // }, [handlesearch])

  const handleaddpage = useCallback(() => {
    dispatch(productSearchRequest({ ...productSearch, page: productSearch.page + 1 }))
  }, [dispatch, productSearch])

  const handlereturnpage = useCallback(() => {
    dispatch(productSearchRequest({ ...productSearch, page: productSearch.page - 1 }))
  }, [dispatch, productSearch])

  const handleorder = useCallback(
    e => {
      dispatch(
        productSearchRequest({
          ...productSearch,
          order: e,
          order2: e.slice(1),
          direction: e.slice(0, 1) === 'y' ? EOrderDirection.DESC : EOrderDirection.ASC,
        })
      )
    },
    [dispatch, productSearch]
  )

  const handlecategory = useCallback(
    e => {
      dispatch(productSearchRequest({ ...productSearch, page: 1, category: e }))
    },
    [dispatch, productSearch]
  )

  useEffect(() => {
    if (products?.length === 1 && products[0].bar_code === codeeeee) {
      dispatch(productEditSave(products[0]))
      history.push('/editproduct')
      setBar_code('')
    }
  }, [codeeeee, dispatch, products])

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0])
        dispatch(productSearchRequest({ ...productSearch, bar_code: results[0] }))
        setBarCodeScanning(false)
        setShowBarcodeScanner(false)

        setResults([])
      }
    }
    loadProduct()
  }, [dispatch, productSearch, results])

  useEffect(() => {
    const values = productSearch

    batch(() => {
      dispatch(categoryRequest())
      dispatch(productSearchRequest(values))
    })

    if (!profile.level) {
      orderOptions.push(
        { id: 'yaverage_cost', title: 'Maior Custo', teste: true },
        { id: 'xaverage_cost', title: 'Menor Custo', teste: true }
      )
    }
    //eslint-disable-next-line
  }, [dispatch, profile.company, profile.level])

  console.log(productSearch)

  return (
    <Container>
      <Form
        onSubmit={() => {}}
        initialData={{
          order: productSearch.order,
          category: productSearch.category,
        }}>
        <Grid columns="2">
          <Select
            key={1}
            name="order"
            placeholder="Ordenar"
            value={productSearch.order}
            onChange={e => handleorder(e.target.value)}
            options={orderOptions}
          />
          <Select
            key={2}
            name="category"
            value={productSearch.category}
            placeholder="Categoria"
            onChange={e => handlecategory(e.target.value)}
            options={options3}
          />
        </Grid>
      </Form>

      <List25>
        <Input
          key={3}
          autoComplete="off"
          placeholder="Pesquisar"
          value={bar_code}
          name="bar_code"
          onChange={e => {
            setCodeeeee(e.target.value)
            setBar_code(e.target.value)
            handlesearch(e.target.value)
          }}
        />
        <FaBarcode key={4} cursor="pointer" onClick={() => handleBarcode()} size={30} color="#FFF" />
      </List25>

      <BarcodeScannerComponentTeste
        BarCodeScanning={showBarcodeScanner}
        handleResults={result => setResults([...results, result])}
        getcamid={getcamid}
      />

      <PageSelectorComponent
        handlereturnpage={handlereturnpage}
        handleaddpage={handleaddpage}
        page={productSearch.page || 1}
        totalpages={productSearch.totalPages || 1}
      />

      <ProductListComponent
        products={products}
        profileLevel={profile.level}
        handleClick={product => handleEdit(product)}
      />
    </Container>
  )
}
