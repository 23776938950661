import React, { useState, useEffect } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { format, addMonths, subMonths, startOfMonth, endOfMonth, parseISO, isAfter } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { saleInventariosRequest } from 'store/Sale/SaleCreators'
import { selectInventarios } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'

import { Container, List3 } from 'screens/Layout/styles'

export default function InventarioScreen() {
  const dispatch = useDispatch()
  const inventarios = useSelector(selectInventarios)
  const profile = useSelector(selectUserProfile)
  const [month, setMonth] = useState(new Date())
  const [showright, setShowright] = useState(false)

  useEffect(() => {
    dispatch(saleInventariosRequest(startOfMonth(month), endOfMonth(month)))
    //eslint-disable-next-line
  }, [dispatch, month, profile?.company])

  function handlePrevMonth() {
    setMonth(subMonths(month, 1))
    if (isAfter(new Date(), subMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }
  function handleNextMonth() {
    setMonth(addMonths(month, 1))
    if (isAfter(new Date(), addMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }

  return (
    <Container>
      <header>
        <>
          <button type="button" onClick={handlePrevMonth}>
            <MdChevronLeft size={30} color="#ffff" />
          </button>
          <strong>{format(month, 'MMMM/YYY', { locale: pt })}</strong>
          {!showright ? null : (
            <button type="button" onClick={handleNextMonth}>
              <MdChevronRight size={30} color="#ffff" />
            </button>
          )}
        </>
      </header>

      <></>

      <ul>
        {inventarios?.data?.map(inventario => (
          <List3 key={String(inventario.id)} noactive={!inventario.active}>
            <span>
              {format(parseISO(inventario.date), "dd'/'MM'-'HH:mm", { locale: pt })} {inventario.name} {inventario.pgto}{' '}
              {inventario.note}
            </span>

            <aside3>
              <ul>
                <span>{inventario.qty} </span>
              </ul>
            </aside3>
          </List3>
        ))}
      </ul>
      <ul />
    </Container>
  )
}
