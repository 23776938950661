export enum EOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IActionStatus {
  type: EActionStatus
  message?: string | null
}

export enum EActionStatus {
  Waiting = 'waiting',
  Busy = 'busy',
  Failure = 'failure',
  Success = 'success',
}

export interface IActionRequest<T> {
  data?: T
  status: IActionStatus
}
