import React from 'react'

import { Card, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { IProduct } from 'types/Product.types'
import { formatPrice } from 'util/format'
export interface IProductListProps {
  products: IProduct[]
  profileLevel: number
  handleClick: (product: IProduct) => void
}

export const ProductListComponent: React.FC<IProductListProps> = ({ products, profileLevel, handleClick }) => {
  return (
    <Grid container display="flex" style={{ marginTop: 10 }}>
      {products?.map(product => {
        const text1 = `${product.bar_code} - ${product.category}`
        const text2 = product.name
        const text3 = `${product.stockpile ? product.stock : ''} ${product.stockpile ? '-' : ''}
         ${profileLevel ? '' : formatPrice(product.average_cost)} ${formatPrice(product.price)}`
        const text4 =
          Number(format(parseISO(product.last_sale), 'yyy')) > 2000
            ? format(parseISO(product.last_sale), "dd'/'MMM'/'yy", {
                locale: pt,
              })
            : null

        return (
          <Grid item lg={12} md={12} sm={12} xs={12} onClick={() => handleClick(product)} key={String(product.id)}>
            <Card
              style={{
                backgroundColor: 'white',
                padding: 6,
                marginTop: 6,
                borderRadius: 4,
                cursor: 'pointer',
                opacity: product.active ? 1 : 0.6,
                color: product.active ? '#999' : '#666',
              }}>
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  {text1 && (
                    <Typography fontSize="10px" padding="2px">
                      {text1}
                    </Typography>
                  )}
                  {text2 && (
                    <Typography fontSize="10px" padding="2px">
                      {text2}
                    </Typography>
                  )}
                </Grid>
                <Grid item textAlign="right" lg={3} md={3} sm={3} xs={3}>
                  {text3 && (
                    <Typography fontSize="10px" padding="2px" style={{ font: 'Roboto' }}>
                      {text3}
                    </Typography>
                  )}
                  {text4 && (
                    <Typography fontSize="10px" padding="2px">
                      {text4}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
