import { IActionRequest, IActionStatus } from 'types/Application.types'
import { IUserAuth, IUserProfile } from 'types/User.types'

export interface IUserState {
  readonly profile?: IUserProfile
  readonly auth?: IUserAuth
  readonly signInStatus?: IActionStatus
  readonly profileUpdateStatus?: IActionStatus
  readonly profileCreateStatus?: IActionStatus
  readonly users?: IActionRequest<IUserProfile[]>
}

export enum EUserTypes {
  USER_SIGN_IN_REQUEST = '@user/SIGN_IN_REQUEST',
  USER_SIGN_IN_RESPONSE = '@user/SIGN_IN_RESPONSE',

  USER_PROFILE_UPDATE_REQUEST = '@user/PROFILE_UPDATE_REQUEST',
  USER_PROFILE_UPDATE_RESPONSE = '@user/PROFILE_UPDATE_RESPONSE',

  USER_PROFILE_CREATE_REQUEST = '@user/PROFILE_CREATE_REQUEST',
  USER_PROFILE_CREATE_RESPONSE = '@user/PROFILE_CREATE_RESPONSE',

  USER_SIGN_OUT_REQUEST = '@user/SIGN_OUT_REQUEST',

  USER_USERS_REQUEST = '@user/USERS_REQUEST',
  USER_USERS_RESPONSE = '@user/USERS_RESPONSE',

  USERS_UPDATE_TOKEN = '@user/UPDATE_TOKEN',
}
