import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectEnterpriseState = (state: ReturnType<typeof combineReducers>) => {
  return state.enterprise
}

export const selectEnterprises = createSelector([selectEnterpriseState], ({ enterprises }) => enterprises || [])

export const selectEnterpriseCompaniesSelect = createSelector(
  [selectEnterpriseState],
  ({ enterprises }) =>
    enterprises?.map(({ company }) => ({
      id: company,
      title: company,
    })) || []
)

export const selectDebts = createSelector([selectEnterpriseState], ({ debts }) => debts || [])

export const selectEnterpriseNewStatus = createSelector(
  [selectEnterpriseState],
  ({ enterpriseNewStatus }) => enterpriseNewStatus
)
