import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import history from 'services/history'
import { PaymentService } from 'services/PaymentService'
import {
  paymentEditRequest,
  paymentEditResponse,
  paymentNewRequest,
  paymentNewResponse,
  paymentRequest,
  paymentResponse,
} from 'store/Payment/PaymentCreators'
import { EPaymentTypes } from 'store/Payment/PaymentCreators.types'
import { EActionStatus } from 'types/Application.types'
import { IPayment } from 'types/Payment.types'

export function* paymentRequestSaga(action: ReturnType<typeof paymentRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getPayments } = new PaymentService()
    const response: AxiosResponse<IPayment[]> = yield getPayments(startDate, endDate)

    yield put(paymentResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(paymentResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar pagamentos')
    }
  }
}

export function* paymentNewRequestSaga(action: ReturnType<typeof paymentNewRequest>) {
  try {
    const { payment } = action.payload
    const { postPayment } = new PaymentService()
    yield postPayment(payment)

    toast.success('Pagamento realizado com sucesso!')
    yield put(paymentNewResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(paymentNewResponse({ type: EActionStatus.Failure }))
      toast.error('Falha criar o pagamento')
    }
  }
}

export function* paymentEditRequestSaga(action: ReturnType<typeof paymentEditRequest>) {
  try {
    const { paymentEdit } = action.payload
    const { putPayment } = new PaymentService()
    yield putPayment(paymentEdit)

    toast.success('Pagamento editado com sucesso!')
    yield put(paymentEditResponse({ type: EActionStatus.Success }, paymentEdit))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(paymentEditResponse({ type: EActionStatus.Failure }))
      toast.error('Falha ao editar o pagamento')
    }
  }
}

export default function* () {
  yield takeLatest(EPaymentTypes.PAYMENT_REQUEST, paymentRequestSaga)
  yield takeLatest(EPaymentTypes.PAYMENT_NEW_REQUEST, paymentNewRequestSaga)
  yield takeLatest(EPaymentTypes.PAYMENT_EDIT_REQUEST, paymentEditRequestSaga)
}
