import React, { useState, useEffect, useMemo } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { Input, Form } from '@rocketseat/unform'

import { format, parseISO, subMonths, addMonths, isAfter, startOfMonth, endOfMonth } from 'date-fns'
import pt from 'date-fns/locale/pt'
import {
  cashLastRequest,
  cashLastResponse,
  cashNewRequest,
  cashNewResponse,
  cashRequest,
} from 'store/Cash/CashCreators'
import { selectCash, selectCashLast, selectCashNewStatus } from 'store/Cash/CashSelectors'
import { paymentRequest } from 'store/Payment/PaymentCreators'
import { saleSearchRequest } from 'store/Sale/SaleCreators'
import { selectSales } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { List6, List20, Container } from 'screens/Layout/styles'
import { selectPayments } from 'store/Payment/PaymentSelectors'
import { formatPrice } from '~/util/format'

import { cashSchema } from './CashScreen.rules'

export default function CashScreen() {
  const dispatch = useDispatch()
  const [totalpayments, setTotalpayments] = useState(0)
  const [totalsales, setTotalsales] = useState(0)
  const [totalcash, setTotalcash] = useState(0)
  const [lastcash, setLastcash] = useState(0)
  const [newcash, setNewcash] = useState()
  const [totaldebit, setTotaldebit] = useState(0)
  const [totalcredit, setTotalcredit] = useState(0)
  const [totalpix, setTotalpix] = useState(0)
  const profile = useSelector(selectUserProfile)
  const [month, setMonth] = useState(new Date())
  const [newdate, setNewdate] = useState(new Date())
  const [changeDate, setChangeDate] = useState(false)
  const [showright, setShowright] = useState(false)

  const cashs = useSelector(selectCash)
  const cashLast = useSelector(selectCashLast)
  const cashNewStatus = useSelector(selectCashNewStatus)

  const sales = useSelector(selectSales)
  const payments = useSelector(selectPayments)

  const dateFormatted = useMemo(() => format(newdate, "YYY-MM-dd'T'HH:mm:ss", { locale: pt }), [newdate])

  useEffect(() => dispatch(cashRequest(startOfMonth(month), endOfMonth(month))), [dispatch, month])

  useEffect(() => {
    dispatch(cashLastRequest())
  }, [dispatch])

  useEffect(() => {
    if (cashLast?.status?.type === EActionStatus.Success) {
      dispatch(cashLastResponse({ type: EActionStatus.Waiting }))
      dispatch(paymentRequest(cashLast.data[0].date, new Date()))
      dispatch(
        saleSearchRequest({
          date1: cashLast.data[0].date,
          date2: new Date(),
          active: true,
        })
      )

      setLastcash(cashLast?.data[0]?.price || 0)
    }
  }, [cashLast, dispatch])

  useEffect(() => {
    if (sales?.status?.type === EActionStatus.Success && payments?.status?.type === EActionStatus.Success) {
      const debit = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto === 'Débito')
        .reduce((result, teste) => result + teste.pgtoValue, 0)

      const pix = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto === 'Pix')
        .reduce((result, teste) => result + teste.pgtoValue, 0)

      const credit = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto === 'Crédito')
        .reduce((result, teste) => result + teste.pgtoValue, 0)

      const money = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto === 'Dinheiro')
        .reduce((result, teste) => result + teste.pgtoValue, 0)

      const debit2 = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto2 === 'Débito')
        .reduce((result, teste) => result + teste.pgto2Value, 0)

      const pix2 = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto2 === 'Pix')
        .reduce((result, teste) => result + teste.pgto2Value, 0)

      const credit2 = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto2 === 'Crédito')
        .reduce((result, teste) => result + teste.pgto2Value, 0)

      const money2 = sales?.data
        .filter(item => item.active === true)
        .filter(x => x.pgto2 === 'Dinheiro')
        .reduce((result, teste) => result + teste.pgto2Value, 0)

      const payments2 = payments?.data
        .filter(item => item.active === true)
        .reduce((result, teste) => result + teste.price, 0)
      const lastCashPrice = cashLast?.data?.length > 0 ? cashLast.data[0].price : 0

      setTotaldebit(debit + debit2)
      setTotalcredit(credit + credit2)
      setTotalpix(pix + pix2)
      setTotalsales(money + money2)
      setTotalpayments(payments2)
      setTotalcash(money + money2 + lastCashPrice - payments2)
    }
  }, [cashLast, payments, sales])

  async function handleCloseCash() {
    dispatch(
      cashNewRequest({
        price: newcash,
        debit: totaldebit,
        credit: totalcredit,
        pix: totalpix,
        money: totalsales,
        payment: totalpayments,
        diff: newcash - totalcash,
        company: profile?.company,
        enterprise: profile?.enterprise,
        date: format(changeDate ? newdate : new Date(), "YYY-MM-dd'T'HH:mm:ss'-03:00'", { locale: pt }),
      })
    )
  }

  useEffect(() => {
    if (cashNewStatus?.type === EActionStatus.Success) {
      setNewdate(new Date())
      dispatch(cashNewResponse({ type: EActionStatus.Waiting }))
      dispatch(cashLastRequest())
      dispatch(cashRequest(startOfMonth(month), endOfMonth(month)))
    }
    //eslint-disable-next-line
  }, [cashNewStatus?.type, dispatch, month])

  function handlePrevMonth() {
    setMonth(subMonths(month, 1))
    if (isAfter(new Date(), subMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }
  function handleNextMonth() {
    setMonth(addMonths(month, 1))
    if (isAfter(new Date(), addMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }
  function handleDate(data) {
    setNewdate(parseISO(data))
    setChangeDate(true)
  }

  return (
    <Container>
      <Form schema={cashSchema} onSubmit={handleCloseCash}>
        <List20>
          {!profile?.level ? (
            <>
              <Input
                name="newcash"
                onChange={e => setNewcash(e.target.value.replace(/,/, '.'))}
                value={newcash}
                placeholder="Dinheiro em Caixa"
              />
              <Input
                name="date"
                value={dateFormatted}
                onChange={e => handleDate(e.target.value)}
                type="datetime-local"
                placeholder="Data do Fechamento"
              />
              {newcash ? (
                <button type="submit" onClick={handleCloseCash}>
                  Fechar Caixa{' '}
                </button>
              ) : null}
            </>
          ) : (
            <span>Último Fechamento: {formatPrice(lastcash)}</span>
          )}
        </List20>
      </Form>

      <List20>
        <List6 key="Data">
          <strong>Data</strong>
          <strong>Cartão</strong>
          <strong>Pix</strong>
          <strong>Dinheiro</strong>
          <strong>Saída</strong>
          <strong>
            Caixa
            <br />
            Diff
          </strong>
        </List6>
        <List6 key="Atual">
          <span />
          <span>{formatPrice(totaldebit + totalcredit)}</span>
          <span>{formatPrice(totalpix)}</span>
          <span>{formatPrice(totalsales)}</span>
          <span>-{formatPrice(totalpayments)}</span>
          <span>{formatPrice(totalcash)}</span>
        </List6>
      </List20>
      <header>
        <button type="button" onClick={handlePrevMonth}>
          <MdChevronLeft size={30} color="#ffff" />
        </button>
        <strong>{format(month, 'MMMM/YYY', { locale: pt })}</strong>
        {!showright ? null : (
          <button type="button" onClick={handleNextMonth}>
            <MdChevronRight size={30} color="#ffff" />
          </button>
        )}
      </header>

      <List20>
        {!!cashs &&
          cashs?.data?.map(cash => (
            <List6 key={cash.id}>
              <span>{format(parseISO(cash.date), "dd'/'MM' 'HH:mm", { locale: pt })}</span>
              <span>{formatPrice(cash.debit + cash.credit)}</span>
              <span>{formatPrice(cash.pix)}</span>
              <span>{formatPrice(cash.money)}</span>
              <span>-{formatPrice(cash.payment)}</span>
              <span>
                {formatPrice(cash.price)}
                <br />
                {formatPrice(cash.diff)}
              </span>
            </List6>
          ))}
      </List20>
    </Container>
  )
}
