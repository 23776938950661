import produce from 'immer'
import { Reducer } from 'redux'
import { ECategoryTypes, ICategoryState } from 'store/Category/CategoryCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { ICategory } from 'types/Category.types'

const CATEGORY_INITIAL_STATE: ICategoryState = {}

const CategoryReducer: Reducer<ICategoryState> = (state = CATEGORY_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ECategoryTypes.CATEGORY_RESPONSE: {
        const {
          payload: { categories },
        } = action as ReturnType<typeof categoryResponse>

        draft.categories = categories

        return draft
      }

      case ECategoryTypes.CATEGORY_NEW_REQUEST: {
        draft.categoryNewStatus = { type: EActionStatus.Busy }

        return draft
      }

      case ECategoryTypes.CATEGORY_NEW_RESPONSE: {
        const {
          payload: { actionStatus },
        } = action as ReturnType<typeof categoryNewResponse>

        draft.categoryNewStatus = actionStatus

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default CategoryReducer

export const categoryRequest = () => ({
  type: ECategoryTypes.CATEGORY_REQUEST,
})

export const categoryResponse = (categories: ICategory[]) => ({
  type: ECategoryTypes.CATEGORY_RESPONSE,
  payload: { categories },
})

export const categoryNewRequest = (category: ICategory) => ({
  type: ECategoryTypes.CATEGORY_NEW_REQUEST,
  payload: { category },
})

export const categoryNewResponse = (actionStatus: IActionStatus) => ({
  type: ECategoryTypes.CATEGORY_NEW_RESPONSE,
  payload: { actionStatus },
})
