import React from 'react'
import { Switch } from 'react-router-dom'

import ChartsScreen from 'screens/Sale/ChartsScreen'
import SaleNewScreen from 'screens/Sale/SaleNewScreen'
import UploadScreen from 'screens/Upload/UploadScreen'
import CashScreen from 'screens/Cash/CashScreen'
import PaymentEditScreen from 'screens/Payment/PaymentEditScreen'
import PaymentsScreen from 'screens/Payment/PaymentsScreen'
import CategoryNewScreen from 'screens/Product/CategoryNewScreen'
import ProductEditScreen from 'screens/Product/ProductEditScreen'
import ProductNewScreen from 'screens/Product/ProductNewScreen'
import ProductScreen from 'screens/Product/ProductScreen'
import StockScreen from 'screens/Product/StockScreen'
import EncalheScreen from 'screens/Sale/EncalheScreen'
import EntradaScreen from 'screens/Sale/EntradaScreen'
import InventarioScreen from 'screens/Sale/InventarioScreen'
import SaleEditScreen from 'screens/Sale/SaleEditScreen'
import SalesScreen from 'screens/Sale/SalesScreen'
import ConditionsScreen from 'screens/User/ConditionsScreen'
import ConfigScreen from 'screens/User/ConfigScreen'
import DepositScreen from 'screens/User/DepositScreen'
import DownloadScreen from 'screens/User/DownloadScreen'
import EnterpriseNewScreen from 'screens/User/EnterpriseNewScreen'
import ProfileScreen from 'screens/User/ProfileScreen'
import SignUpScreen from 'screens/User/SignUpScreen'
import UserSignInScreen from 'screens/User/UserSignInScreen'
import UserSignOutScreen from 'screens/User/UserSignOutScreen'
import UsersScreen from 'screens/User/UsersScreen'

import Route from './Route'

export default function Routes() {
  return (
    <Switch>
      <Route path="/conditions" component={ConditionsScreen} />
      <Route path="/deposit" component={DepositScreen} />

      <Route path="/signout" component={UserSignOutScreen} />
      <Route path="/" exact component={UserSignInScreen} isLogin />

      <Route path="/products" component={ProductScreen} isPrivate />
      <Route path="/newproduct" component={ProductNewScreen} isPrivate />
      <Route path="/editproduct" component={ProductEditScreen} isPrivate />
      <Route path="/stock" component={StockScreen} isPrivate />
      <Route path="/newcategory" component={CategoryNewScreen} isPrivate />

      <Route path="/users" component={UsersScreen} isPrivate />
      <Route path="/register" component={SignUpScreen} isPrivate />
      <Route path="/config" component={ConfigScreen} isPrivate />
      <Route path="/newenterprise" component={EnterpriseNewScreen} isPrivate />

      <Route path="/cash" component={CashScreen} isPrivate />

      <Route path="/payments" component={PaymentsScreen} isPrivate />
      <Route path="/editpayment" component={PaymentEditScreen} isPrivate />

      <Route path="/encalhe" component={EncalheScreen} isPrivate />
      <Route path="/inventario" component={InventarioScreen} isPrivate />
      <Route path="/entrada" component={EntradaScreen} isPrivate />
      <Route path="/profile" component={ProfileScreen} isPrivate />
      <Route path="/downloads" component={DownloadScreen} isPrivate />
      <Route path="/sales" component={SalesScreen} isPrivate />
      <Route path="/editsale" component={SaleEditScreen} isPrivate />
      <Route path="/charts" component={ChartsScreen} isPrivate />
      <Route path="/upload" component={UploadScreen} isPrivate />

      <Route path="/newsale" component={SaleNewScreen} isPrivate />

      <Route path="/" component={() => <h1>404 - Page not found</h1>} />
    </Switch>
  )
}
