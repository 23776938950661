import React, { useCallback } from 'react'

import { Box, Card, Grid, Typography, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MdDelete } from 'react-icons/md'

import { formatPrice } from 'util/format'
import { ISaleNew } from 'types/Sale.types'

export interface ISaleNewListProps {
  sales: ISaleNew[]
  handleEdit: (sales: ISaleNew[]) => void
  show: boolean
}

export const SaleNewListComponent: React.FC<ISaleNewListProps> = ({ sales, handleEdit, show = true }) => {
  const handleNote = useCallback(
    (indice: number, note: string) => {
      handleEdit(
        sales?.map(sale => {
          if (sale.indice !== indice) return sale

          return {
            ...sale,
            note: sale.indice === indice ? note : sale.note,
          }
        })
      )
    },
    [sales, handleEdit]
  )

  const handleDiscount = useCallback(
    (indice: number, value: string) => {
      handleEdit(
        sales?.map(sale => {
          if (sale.indice !== indice) return sale

          const discount = Number.parseFloat(value || '0')

          return {
            ...sale,
            discount,
            total_price: sale.qty * sale.price - discount,
          }
        })
      )
    },
    [sales, handleEdit]
  )

  const handleQty = useCallback(
    (indice: number, value: number) => {
      handleEdit(
        sales?.map(sale => {
          if (sale.indice !== indice) return sale

          return {
            ...sale,
            qty: value,
            total_price: value * sale.price - (sale.discount || 0),
          }
        })
      )
    },
    [sales, handleEdit]
  )

  const handleDelete = useCallback(
    (indice: number) => {
      handleEdit(sales.filter(sale => sale.indice !== indice))
    },
    [sales, handleEdit]
  )

  const SaleNewListComponentStyle = makeStyles({
    OutlinedTextField: {
      backgroundColor: '#F5F5F5 !important',
      borderWidth: 0,
      outline: 'none',
      borderColor: 'white !important',
      border: 'none !important',
      padding: 0,
      margin: '0  !important',
      marginBottom: 0,
      fontSize: 12,
      color: 'black !important',
      textDecorationColor: 'black',
      '&::placeholder': {
        color: 'black !important',
      },
    },
    textField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
    },
    descField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
    },
    qtdField: {
      width: '100% !important',
      padding: '5px  5px 5px 0 !important',
      margin: '0 0 10px 0 !important',
    },
    qtdInput: {
      margin: '0  !important',
      padding: '0  !important',
      color: 'black !important',
      textAlign: 'center',
      minHeight: '30px !important',
      minWidth: '50px !important',
    },
  })

  const styles = SaleNewListComponentStyle()

  return (
    <Grid container display="flex" style={{ marginTop: 10 }}>
      {show &&
        sales?.map((productBasket, _id) => (
          <Grid item lg={12} md={12} sm={12} xs={12} key={String(productBasket.indice)}>
            <Card
              style={{
                backgroundColor: 'white',
                padding: 6,
                marginTop: 6,
                borderRadius: 4,
                color: 'black',
              }}>
              <Grid container flexDirection="row">
                <Grid item flexDirection="column" lg={6} md={6} sm={6} xs={6}>
                  <Typography>
                    {productBasket.bar_code}-{productBasket.name}
                  </Typography>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      component="form"
                      // sx={{
                      //   '& > :not(style)': { m: 1, width: '50ch', fontSize: 10, padding: 0, border: 0, margin: 0 },
                      // }}
                      noValidate
                      autoComplete="off">
                      <TextField
                        defaultValue={productBasket.note}
                        id="outlined-basic"
                        variant="filled"
                        placeholder="Observação"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleNote(productBasket.indice, event.target.value)
                        }
                        inputProps={{
                          className: styles.OutlinedTextField,
                        }}
                        className={styles.textField}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item display="flex" textAlign="right" lg={6} md={6} sm={6} xs={6}>
                  <Grid item display="flex" lg={5} md={5} sm={5} xs={5} textAlign="right">
                    <Box alignSelf="center">
                      <TextField
                        label="Qtd"
                        defaultValue={productBasket.qty}
                        type="number"
                        inputProps={{
                          className: styles.qtdInput,
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleQty(productBasket.indice, Number(event.target.value))
                        }
                        className={styles.qtdField}></TextField>
                      <TextField
                        label="Desc."
                        variant="outlined"
                        defaultValue={productBasket.discount || 0}
                        type="number"
                        inputProps={{
                          className: styles.qtdInput,
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleDiscount(productBasket.indice, event.target.value)
                        }
                        className={styles.descField}></TextField>
                    </Box>
                  </Grid>
                  <Box alignSelf="center" textAlign="center" flex={1}>
                    <Typography>{formatPrice(productBasket.price)}</Typography>
                    <Typography>{formatPrice(productBasket.total_price)}</Typography>
                  </Box>
                  <Box alignSelf="center">
                    <MdDelete size="20" onClick={() => handleDelete(productBasket.indice)} />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
    </Grid>
  )
}
