import React, { useRef, useState } from 'react'

import Result from 'components/BarCode/Result'
import Scanner from 'components/BarCode/Scanner'

export interface IBarcodeScannerProps {
  BarCodeScanning: boolean
  getcamid: string
  handleResults: (results: any[]) => void
}

export const BarcodeScannerComponentTeste: React.FC<IBarcodeScannerProps> = ({
  BarCodeScanning,
  handleResults,
  getcamid,
}) => {
  const scannerRef = useRef(null)

  const [results, setResults] = useState<any[]>([])

  return BarCodeScanning ? (
    <div>
      <ul className="results">
        {results.map(result => result.codeResult && <Result key={result.codeResult.code} result={result} />)}
      </ul>
      <div ref={scannerRef} style={{ position: 'relative' }}>
        <canvas
          className="drawingBuffer"
          style={{
            position: 'absolute',
            top: '0px',
            border: '3px solid green',
          }}
          width="480"
          height="350"
        />

        <Scanner
          aaaa={getcamid}
          scannerRef={scannerRef}
          teste={results}
          onDetected={(result: any) => {
            setResults([...results, result])
            handleResults([...results, result])
          }}
        />
      </div>
    </div>
  ) : null
}
