import { IActionRequest, IActionStatus } from 'types/Application.types'
import { IProduct, IProductSearch, IProductStock } from 'types/Product.types'

export interface IProductState {
  readonly products?: IProduct[]
  readonly productSearch?: IProductSearch
  readonly productEdit?: IProduct
  readonly productNew?: IProduct
  readonly productStock?: IActionRequest<IProductStock[]>
  readonly productEditStockStatus?: IActionStatus
}

export enum EProductTypes {
  PRODUCT_SEARCH_REQUEST = '@product/SEARCH_REQUEST',
  PRODUCT_SEARCH_RESPONSE = '@product/SEARCH_RESPONSE',

  PRODUCT_EDIT_SAVE = '@product/EDIT_SAVE',
  PRODUCT_EDIT_REQUEST = '@product/EDIT_REQUEST',
  PRODUCT_EDIT_RESPONSE = '@product/EDIT_RESPONSE',

  PRODUCT_NEW_REQUEST = '@product/NEW_REQUEST',
  PRODUCT_NEW_RESPONSE = '@product/NEW_RESPONSE',

  PRODUCT_STOCK_REQUEST = '@product/STOCK_REQUEST',
  PRODUCT_STOCK_RESPONSE = '@product/STOCK_RESPONSE',

  PRODUCT_EDIT_STOCK_REQUEST = '@product/EDIT_STOCK_REQUEST',
  PRODUCT_EDIT_STOCK_RESPONSE = '@product/EDIT_STOCK_RESPONSE',
}
