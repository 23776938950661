import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userUsersRequest } from 'store/User/UserCreators'
import { selectUserUsers } from 'store/User/UserSelectors'

import { Container6, List20, List7 } from 'screens/Layout/styles'

// import AvatarInput from './AvatarInput';
// import { Container } from './styles';

export default function UsersScreen() {
  const dispatch = useDispatch()

  const userUsers = useSelector(selectUserUsers)

  useEffect(() => {
    dispatch(userUsersRequest())
  }, [dispatch])

  return (
    <Container6>
      <List20>
        {userUsers?.data?.map(user => (
          <List7 key={String(user.id)}>
            <strong>{user.username}</strong>
            <strong>{user.company}</strong>
            <strong>{user.level}</strong>
          </List7>
        ))}
      </List20>
    </Container6>
  )
}
