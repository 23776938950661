import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectPaymentState = (state: ReturnType<typeof combineReducers>) => {
  return state.payment
}

export const selectPayments = createSelector([selectPaymentState], ({ payments }) => payments || [])

export const selectPaymentNewStatus = createSelector([selectPaymentState], ({ paymentNewStatus }) => paymentNewStatus)

export const selectPaymentEdit = createSelector([selectPaymentState], ({ paymentEdit }) => paymentEdit)
