import React, { useState, useEffect } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { RiAddCircleLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Form, Input, Check, Select } from '@rocketseat/unform'

import { Container6, List10, List11 } from 'screens/Layout/styles'
import { productEditSchema } from 'screens/Product/ProductScreen.rules'
import history from 'services/history'
import { categoryRequest } from 'store/Category/CategoryCreators'
import { selectCategoriesSelect } from 'store/Category/CategorySelectors'
import { productEditRequest } from 'store/Product/ProductCreators'
import { selectProductEdit } from 'store/Product/ProductSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'

function ProductEditScreen() {
  const dispatch = useDispatch()
  const profile = useSelector(selectUserProfile)
  const productEdit = useSelector(selectProductEdit || {})
  const categoriesSelect = useSelector(selectCategoriesSelect)

  const [showaddstock, setShowaddstock] = useState(false)
  const [categoryvalue, setCategoryvalue] = useState(productEdit?.category)

  useEffect(() => {
    dispatch(categoryRequest())
  }, [dispatch, profile.company])

  function handleSubmit(data) {
    dispatch(productEditRequest({ ...productEdit, ...data }))
    history.push('/products')
  }

  return (
    <Container6>
      <Form schema={productEditSchema} initialData={productEdit} onSubmit={handleSubmit}>
        <Link to="/products">
          <MdChevronLeft size={30} />
        </Link>
        <span>Código</span>
        <Input name="bar_code" placeholder="Código do Produto" />
        <span>Descrição</span>
        <Input name="name" placeholder="Nome do Produto" />
        <span>Categoria</span>
        <Select
          name="category"
          placeholder="Categoria do Produto"
          onChange={e => setCategoryvalue(e.target.value)}
          value={categoryvalue}
          options={categoriesSelect}
        />

        <List10>
          <span>Preço</span>
          {profile?.level ? null : <span>Custo</span>}
        </List10>
        <List10>
          <Input name="price" placeholder="Preço" width="20px" />
          {profile?.level ? <></> : <Input name="average_cost" placeholder="Custo" />}
        </List10>

        <span>Estoque</span>
        <List11>
          <ul>{profile?.level ? <></> : <Check name="stockpile" />}</ul>
          {profile?.level ? (
            <Input width="20px" name="stock" disabled placeholder="Estoque" />
          ) : (
            <Input width="20px" name="stock" placeholder="Estoque" />
          )}

          {profile?.level ? (
            <></>
          ) : (
            <RiAddCircleLine size={30} color="#FFF" onClick={() => setShowaddstock(!showaddstock)} />
          )}
        </List11>
        {!showaddstock ? null : (
          <>
            <span>Entrada no Estoque</span>
            <List10>
              <Input name="addstock" placeholder="Quantidade" width="20px" />
              <Input name="addstockprice" placeholder="Custo médio" width="20px" />
            </List10>
          </>
        )}
        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="10px" name="active" />
          </ul>
          <button type="submit">Atualizar Produto</button>
        </List11>
      </Form>
    </Container6>
  )
}

export default ProductEditScreen
