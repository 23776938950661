import produce from 'immer'
import { Reducer } from 'redux'
import { EFileTypes, IFileState } from 'store/File/FileCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { IFile } from 'types/File.types'

const FILE_INITIAL_STATE: IFileState = {}

const FileReducer: Reducer<IFileState> = (state = FILE_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EFileTypes.FILE_NEW_REQUEST: {
        draft.fileNew = {
          status: {
            type: EActionStatus.Busy,
          },
        }

        return draft
      }

      case EFileTypes.FILE_NEW_RESPONSE: {
        const {
          payload: { actionStatus, fileNew },
        } = action as ReturnType<typeof fileNewResponse>

        draft.fileNew = {
          status: actionStatus,
          data: fileNew ?? draft.fileNew?.data,
        }

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default FileReducer

export const fileNewRequest = (fileNew: FormData) => ({
  type: EFileTypes.FILE_NEW_REQUEST,
  payload: { fileNew },
})

export const fileNewResponse = (actionStatus: IActionStatus, fileNew?: IFile) => ({
  type: EFileTypes.FILE_NEW_RESPONSE,
  payload: { actionStatus, fileNew },
})
