import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'
import { FaBarcode } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Container2 } from 'screens/Layout/styles'
import { formatPrice } from '~/util/format'
import history from '~/services/history'
import { selectUserProfile } from 'store/User/UserSelectors'
import { productEditStockRequest, productSearchRequest } from 'store/Product/ProductCreators'
import { selectProducts, selectProductSearch } from 'store/Product/ProductSelectors'
import { saleNewRequest, saleNewResponse } from 'store/Sale/SaleCreators'
import { selectSaleNew } from 'store/Sale/SaleSelectors'
import { EActionStatus } from 'types/Application.types'
import { ProductListComponent } from 'components2/ProductList/ProductListComponent'
import { SaleNewListComponent } from 'components2/SaleNewList/SaleNewListComponent'
import { SaleNewPgtoComponent } from 'components2/SaleNewPgto/SaleNewPgtoComponent'
import { PageSelectorComponent } from 'components2/PageSelector/PageSelector'
import { Typography, Box, Grid, Input } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BarcodeScannerComponentTeste } from 'components2/BarCodeScanner/BarCodeScannerTeste'

const itens_por_pagina = 2

export default function SaleNewScreen() {
  const SaleNewStyle = makeStyles({
    qtdInput: {
      margin: '0  !important',
      padding: '0  !important',
      color: 'black !important',
      textAlign: 'center',
      minHeight: '30px !important',
      minWidth: '50px !important',
    },
  })
  const styles = SaleNewStyle()

  const dispatch = useDispatch()
  const productSearch = useSelector(selectProductSearch)
  const products = useSelector(selectProducts)
  const saleNew = useSelector(selectSaleNew)
  const [sale, setSale] = useState([])
  const [basket, setBasket] = useState([])
  const [bar_code, setBar_code] = useState('')
  const [qty, setQty] = useState(1)
  const [indice, setIndice] = useState(0)
  const profile = useSelector(selectUserProfile)
  const [page, setPage] = useState(1)
  const [BarCodeScanning, setBarCodeScanning] = useState(false)
  const [results, setResults] = useState([])
  const [getcamid, setGetcamid] = useState()
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false)

  const totalCompra = useMemo(() => basket.reduce((result, item) => result + item.total_price, 0), [basket])

  useEffect(() => {
    const data = products?.map(bb => ({
      ...bb,
      priceFormatted: formatPrice(bb.price),
      total_price: qty * bb.price,
      indice,
      totalFormatted: formatPrice(qty * bb.price),
      qty,
      discount: 0,
    }))

    if (!data) return

    setSale(data)
    // setBar_code(codigo)
    // setTotalpages(Math.ceil(products?.data?.total_products / itens_por_pagina))

    if (data.length === 1 && data[0].bar_code === productSearch.bar_code) {
      if (data[0].active) {
        setBasket([data[0], ...basket])
        // setTotal_compra(total_compra+data[0].total_price)
        setSale([])
        setBar_code('')
        setIndice(indice + 1)
        setQty(1)
      }
    }
    // eslint-disable-next-line
  }, [products])

  const loadproducts = useCallback(
    codigo => {
      if (codigo) {
        if (codigo[0].length > 1) {
          codigo = codigo[0]
        }
      }
      setBarCodeScanning(false)
      setShowBarcodeScanner(false)
      setResults([])

      if (codigo.length >= 2) {
        dispatch(
          productSearchRequest({
            bar_code: codigo,
            itens_por_pagina,
            page,
          })
        )
      } else {
        setSale([])
      }
    },
    [dispatch, page]
  )

  function handleFinalizeAll(pgto, pgto2, pgtoValue, pgto2Value, troco) {
    basket.map(sale => handleFinalize(pgto, pgto2, pgtoValue, pgto2Value, troco, sale))
  }

  async function handleFinalize(pgto, pgto2, pgtoValue, pgto2Value, troco, e) {
    let newPgtoValue
    let newPgto2Value

    newPgtoValue = pgtoValue
    newPgto2Value = pgto2Value

    if (pgtoValue > 0) {
      if (troco < 0) {
        toast.error('Troco não pode ser menor que zero')
        return
      }
      if (troco > 0) {
        if (pgto !== 'Dinheiro' && pgto2 !== 'Dinheiro') {
          toast.error('Troco não pode menor que o Dinheiro recebido')
          return
        }

        if (pgto === 'Dinheiro') {
          if (pgtoValue < troco) {
            toast.error('Troco não pode menor que o Dinheiro recebido')
            return
          }
          newPgtoValue = pgtoValue - troco
        }

        if (pgto2 === 'Dinheiro') {
          if (pgto2Value < troco) {
            toast.error('Troco não pode menor que o Dinheiro recebido')
            return
          }
          newPgto2Value = pgto2Value
        }
      }
      if (!pgto2) newPgtoValue = e.total_price
    }

    const {
      // eslint-disable-next-line no-shadow
      bar_code,
      name,
      discount,
      description,
      category,
      price,
      qty,
      total_price,
      id,
      note,
      stockpile,
    } = e

    dispatch(
      saleNewRequest({
        bar_code,
        seller: profile?.username,
        name,
        discount,
        description,
        category,
        active: true,
        price,
        qty,
        total_price: Number.parseFloat(total_price).toFixed(2),
        pgto,
        pgto2,
        pgtoValue: newPgtoValue === 0 ? total_price : newPgtoValue,
        pgto2Value: newPgto2Value,
        note,
        productId: id,
        company: profile?.company,
        enterprise: profile?.enterprise,
      })
    )

    setBasket([])
    if (stockpile) {
      dispatch(productEditStockRequest(e.id, pgto === 'Entrada' ? -qty : qty))
    }
  }

  useEffect(() => {
    if (saleNew?.status?.type === EActionStatus.Success) {
      dispatch(saleNewResponse({ type: EActionStatus.Waiting }))
      history.push('/sales')
    }
  }, [dispatch, saleNew])

  function handleAdd(e) {
    if (e.active) {
      setBasket([e, ...basket])
      setSale([])
      setBar_code('')
      setIndice(indice + 1)
      setQty(1)
      setPage(1)
    }
  }

  useEffect(() => {
    async function results_barcode() {
      if (results[0]) {
        setBar_code(results[0])
        loadproducts(results[0])
        setBarCodeScanning(false)
        setShowBarcodeScanner(false)
        setResults([])
      }
    }
    results_barcode()
    // eslint-disable-next-line
  }, [results])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner)
    setBarCodeScanning(!BarCodeScanning)
  }

  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile?.cam_id) {
          setGetcamid(mediaDevice.deviceId)
        }
      })
    }

    loadCam_id()
  }, [profile.cam_id])

  const handlesearch = useCallback(
    e => {
      setPage(1)
      setBar_code(e)
      loadproducts(e)
    },
    [loadproducts]
  )

  useEffect(() => {
    async function pages() {
      loadproducts(bar_code)
    }
    pages()
    // eslint-disable-next-line
  }, [page])

  return (
    <Container2>
      <Box className={styles.qtdInput} textAlign="center">
        <Typography>{formatPrice(totalCompra)}</Typography>
      </Box>

      <Grid container display="flex" alignItems="center" flexDirection="row">
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Input
            fullWidth
            name="qty"
            type="number"
            step="0.01"
            size="1"
            onChange={e => setQty(e.target.value)}
            value={qty}
            placeholder="Qtd"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Input
            fullWidth
            size="18"
            autoComplete="off"
            value={bar_code}
            onChange={e => handlesearch(e.target.value)}
            name="bar_code"
            placeholder="Pesquisar Produto"
          />
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <FaBarcode cursor="pointer" size={30} onClick={() => handleBarcode()} color="#FFF" />
        </Grid>
      </Grid>

      <SaleNewPgtoComponent basket={basket} handleSend={handleFinalizeAll} show={!basket[0] || sale[0]} />

      <BarcodeScannerComponentTeste
        BarCodeScanning={showBarcodeScanner}
        handleResults={result => setResults([...results, result])}
        getcamid={getcamid}
      />

      <PageSelectorComponent
        handlereturnpage={() => setPage(page - 1)}
        handleaddpage={() => setPage(page + 1)}
        page={productSearch.page || 1}
        totalpages={productSearch.totalPages || 1}
        show={!!sale[0]}
      />

      <ProductListComponent
        handleClick={product => {
          handleAdd(product)
        }}
        products={sale}
        profileLevel={profile.level}
      />

      <SaleNewListComponent sales={basket} handleEdit={sales => setBasket(sales)} show={!sale[0]} />
    </Container2>
  )
}
