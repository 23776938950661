import React from 'react'

import { Card, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { formatPrice } from 'util/format'
import { ISale } from 'types/Sale.types'

export interface ISaleListProps {
  sales: ISale[]
  handleClick: (sale: ISale) => void
}

export const SaleListComponent: React.FC<ISaleListProps> = ({ sales, handleClick }) => {
  return (
    <Grid container display="flex" style={{ marginTop: 10 }}>
      {sales?.map((sale, _id) => {
        const text1 = `${sale.name}`
        const text2 = ` ${format(parseISO(sale.date), "dd'/'MM'/'yy'-'HH:mm", { locale: pt })}
          ${sale.pgto} ${sale.pgto2 ?? ''} ${sale.note ?? ''}`

        const text3 = `${sale.qty} x ${formatPrice(sale.price)}`
        const text4 = `${formatPrice(sale.total_price)}`

        return (
          <Grid item lg={12} md={12} sm={12} xs={12} onClick={() => handleClick(sale)} key={String(sale.id)}>
            <Card
              style={{
                backgroundColor: 'white',
                padding: 6,
                marginTop: 6,
                borderRadius: 4,
                cursor: 'pointer',
                opacity: sale.active ? 1 : 0.6,
                color: sale.active ? '#999' : '#666',
              }}>
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  {text1 && (
                    <Typography fontSize="10px" padding="2px">
                      {text1}
                    </Typography>
                  )}
                  {text2 && (
                    <Typography fontSize="10px" padding="2px">
                      {text2}
                    </Typography>
                  )}
                </Grid>
                <Grid item textAlign="right" lg={3} md={3} sm={3} xs={3}>
                  {text3 && (
                    <Typography fontSize="10px" padding="2px" style={{ font: 'Roboto' }}>
                      {text3}
                    </Typography>
                  )}
                  {text4 && (
                    <Typography fontSize="10px" padding="2px">
                      {text4}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
