import { IActionRequest, IActionStatus } from 'types/Application.types'
import { ICash } from 'types/Cash.types'

export interface ICashState {
  readonly cash?: IActionRequest<ICash[]>
  readonly cashLast?: IActionRequest<ICash[]>
  readonly cashNewStatus?: IActionStatus
}

export enum ECashTypes {
  CASH_REQUEST = '@cash/REQUEST',
  CASH_RESPONSE = '@cash/RESPONSE',

  CASH_LAST_REQUEST = '@cash/LAST_REQUEST',
  CASH_LAST_RESPONSE = '@cash/LAST_RESPONSE',

  CASH_NEW_REQUEST = '@cash/NEW_REQUEST',
  CASH_NEW_RESPONSE = '@cash/NEW_RESPONSE',
}
