import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Input } from '@rocketseat/unform'

import { userSignInSchema } from 'screens/User/UserSignInScreen.rules'
import history from 'services/history'
import { userSignInRequest, userSignInResponse } from 'store/User/UserCreators'
import { selectUserSignInStatus } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { List27Auth } from 'screens/Layout/styles'

export default function UserSignInScreen() {
  const dispatch = useDispatch()
  const userSignInStatus = useSelector(selectUserSignInStatus)

  const loading = useMemo(() => userSignInStatus === EActionStatus.Busy, [userSignInStatus])

  function handleSubmit(userSignIn) {
    dispatch(userSignInRequest(userSignIn))
  }

  function handleloginteste() {
    dispatch(userSignInRequest({ username: 'pedro@teste', password: 'senha' }))
  }

  useEffect(() => {
    if (userSignInStatus?.type === EActionStatus.Success) {
      history.push('/sales')

      dispatch(userSignInResponse({ type: EActionStatus.Waiting }))
    }
    //eslint-disable-next-line
  }, [dispatch, userSignInStatus?.type])

  return (
    <>
      <Form schema={userSignInSchema} onSubmit={handleSubmit}>
        <List27Auth>
          <Input name="username" autoComplete="off" placeholder="Usuário" />
        </List27Auth>
        <List27Auth>
          <Input name="password" autoComplete="off" type="password" placeholder="Senha" />
        </List27Auth>
        <List27Auth>
          <button type="submit">{loading ? 'Carregando...' : 'Entrarr'}</button>
        </List27Auth>
      </Form>

      <Form>
        <List27Auth>
          <button type="button" onClick={() => handleloginteste()}>
            Experimente!!!
          </button>
        </List27Auth>
      </Form>
    </>
  )
}
