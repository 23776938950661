import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'vendas',
      storage,
      whitelist: ['cash', 'category', 'enterprise', 'payment', 'product', 'sale', 'user', 'file'],
    },
    reducers
  )
  return persistedReducer
}
