import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectUserState = (state: ReturnType<typeof combineReducers>) => {
  return state.user
}

export const selectUserAuth = createSelector([selectUserState], ({ auth }) => auth)

export const selectUserProfile = createSelector([selectUserState], ({ profile }) => profile)

export const selectUserSignInStatus = createSelector([selectUserState], ({ signInStatus }) => signInStatus)

export const selectUserUsers = createSelector([selectUserState], ({ users }) => users)
