/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { batch, useDispatch, useSelector } from 'react-redux'

import { Form, Input, Select } from '@rocketseat/unform'

import { format, addMonths, subMonths, startOfMonth, endOfMonth, parseISO, isAfter } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { paymentNewSchema, paymentTypeOptions } from 'screens/Payment/Payment.rules'
import {
  paymentEditResponse,
  paymentNewRequest,
  paymentNewResponse,
  paymentRequest,
} from 'store/Payment/PaymentCreators'
import { selectPaymentNewStatus, selectPayments } from 'store/Payment/PaymentSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container, List3, List21, List10, List20 } from 'screens/Layout/styles'
import history from '~/services/history'
import { formatPrice } from '~/util/format'

export default function PaymentsScreen() {
  const dispatch = useDispatch()

  const paymentNewStatus = useSelector(selectPaymentNewStatus)

  const payments = useSelector(selectPayments)

  const profile = useSelector(selectUserProfile)
  const [month, setMonth] = useState(new Date())
  const [selectedcategory, setSelectedcategory] = useState()
  const [showright, setShowright] = useState(false)

  const date1 = useMemo(() => startOfMonth(month), [month])
  const date2 = useMemo(() => endOfMonth(month), [month])
  const loading = useMemo(
    () => paymentNewStatus?.type === EActionStatus.Busy,

    //eslint-disable-next-line
    [paymentNewStatus?.type]
  )

  useEffect(() => {
    if (paymentNewStatus?.type === EActionStatus.Success) {
      batch(() => {
        dispatch(paymentRequest(date1, date2))
        dispatch(paymentNewResponse({ type: EActionStatus.Waiting }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paymentNewStatus?.type])

  useEffect(() => {
    dispatch(paymentRequest(date1, date2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, profile?.company])

  function handlePrevMonth() {
    setMonth(subMonths(month, 1))
    if (isAfter(new Date(), subMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }
  function handleNextMonth() {
    setMonth(addMonths(month, 1))
    if (isAfter(new Date(), addMonths(month, 2))) {
      setShowright(true)
    } else {
      setShowright(false)
    }
  }

  async function handleSubmit(e) {
    dispatch(
      paymentNewRequest({
        category: selectedcategory,
        company: profile?.company,
        enterprise: profile?.enterprise,
        price: e.price,
        description: e.description,
        seller: profile?.username,
        date: new Date(),
      })
    )
  }

  function handleEdit(data) {
    dispatch(paymentEditResponse({ type: EActionStatus.Waiting }, data))

    history.push('/editpayment')
  }

  return (
    <Container>
      <>
        <List21>
          <button type="button" onClick={handlePrevMonth}>
            <MdChevronLeft size={30} color="#ffff" />
          </button>
          <strong>{format(month, 'MMMM/YYY', { locale: pt })}</strong>
          {!showright ? null : (
            <button type="button" onClick={handleNextMonth}>
              <MdChevronRight size={30} color="#ffff" />
            </button>
          )}
        </List21>
      </>

      <Form schema={paymentNewSchema} onSubmit={handleSubmit}>
        <List10>
          <Input name="price" placeholder="Valor" />
          <Select
            name="category"
            placeholder="Categoria"
            onChange={e => setSelectedcategory(e.target.value)}
            options={paymentTypeOptions}
          />
        </List10>
        <Input name="description" placeholder="Descrição" />

        {!loading ? <button type="submit"> Efeturar Pagamento</button> : <button type="button">Carregando...</button>}
      </Form>

      <List20>
        {payments?.data?.map((payment, _id) => (
          <List3 onClick={() => handleEdit(payment)} key={String(payment.id)} noactive={!payment.active}>
            <span>
              {format(parseISO(payment.created_at), "dd'/'MM'-'HH:mm", { locale: pt })} {payment.description}
            </span>

            <aside3>
              <strong>{formatPrice(payment.price)}</strong>
            </aside3>
          </List3>
        ))}
      </List20>
    </Container>
  )
}
