import { IActionStatus } from 'types/Application.types'
import { ICategory } from 'types/Category.types'

export interface ICategoryState {
  readonly categories?: ICategory[]
  readonly categoryNewStatus?: IActionStatus
}

export enum ECategoryTypes {
  CATEGORY_REQUEST = '@category/REQUEST',
  CATEGORY_RESPONSE = '@category/RESPONSE',

  CATEGORY_NEW_REQUEST = '@category/NEW_REQUEST',
  CATEGORY_NEW_RESPONSE = '@category/NEW_RESPONSE',
}
