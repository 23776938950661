import { combineReducers } from 'redux'

import ProductReducer from 'store/Product/ProductCreators'
import CategoryReducer from 'store/Category/CategoryCreators'
import UserReducer from 'store/User/UserCreators'
import EnterpriseReducer from 'store/Enterprise/EnterpriseCreators'
import CashReducer from 'store/Cash/CashCreators'
import PaymentReducer from 'store/Payment/PaymentCreators'
import SaleReducer from 'store/Sale/SaleCreators'
import FileReducer from 'store/File/FileCreators'

export default combineReducers({
  cash: CashReducer,
  category: CategoryReducer,
  enterprise: EnterpriseReducer,
  payment: PaymentReducer,
  product: ProductReducer,
  sale: SaleReducer,
  user: UserReducer,
  file: FileReducer,
})
