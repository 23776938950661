import * as Yup from 'yup'

export const userSignInSchema = Yup.object().shape({
  username: Yup.string().required('O usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
})

export const userSignUpSchema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  username: Yup.string().required('O usuário é obrigatório'),
  password: Yup.string().min(6, 'No mínimo 6 caracteres').required('A senha é obrigatória'),
})
