import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectCategoryState = (state: ReturnType<typeof combineReducers>) => {
  return state.category
}

export const selectCategories = createSelector([selectCategoryState], ({ categories }) => categories || [])

export const selectCategoriesSelect = createSelector(
  [selectCategoryState],
  ({ categories }) =>
    categories?.map(({ category }) => ({
      id: category,
      title: category,
    })) || []
)

export const selectCategoryNewStatus = createSelector(
  [selectCategoryState],
  ({ categoryNewStatus }) => categoryNewStatus
)
