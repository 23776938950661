import { IActionRequest, IActionStatus } from 'types/Application.types'
import { IPayment } from 'types/Payment.types'

export interface IPaymentState {
  readonly payments?: IActionRequest<IPayment[]>
  readonly paymentNewStatus?: IActionStatus
  readonly paymentEdit?: IActionRequest<IPayment>
}

export enum EPaymentTypes {
  PAYMENT_REQUEST = '@payment/REQUEST',
  PAYMENT_RESPONSE = '@payment/RESPONSE',

  PAYMENT_NEW_REQUEST = '@payment/NEW_REQUEST',
  PAYMENT_NEW_RESPONSE = '@payment/NEW_RESPONSE',

  PAYMENT_EDIT_REQUEST = '@payment/EDIT_REQUEST',
  PAYMENT_EDIT_RESPONSE = '@payment/EDIT_RESPONSE',
}
