import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'

import { enterpriseNewRequest, enterpriseNewResponse } from 'store/Enterprise/EnterpriseCreators'
import { selectEnterpriseNewStatus } from 'store/Enterprise/EnterpriseSelectors'
import { userSignInRequest } from 'store/User/UserCreators'
import { EActionStatus } from 'types/Application.types'

import Input from '~/components/Input'
import { Container } from 'screens/Layout/styles'

export default function EnterpriseNewScreen() {
  const dispatch = useDispatch()
  const [username, setUsername] = useState('admin@')
  const [password, setPassword] = useState('')

  const enterpriseNewStatus = useSelector(selectEnterpriseNewStatus)

  async function handleSubmit(data) {
    dispatch(
      enterpriseNewRequest({
        ...data,
        company: data.enterprise,
        avatar_id: 23,
        plan: 30,
        balance: 90,
        last_debt: new Date(),
      })
    )
  }

  useEffect(() => {
    if (enterpriseNewStatus?.type === EActionStatus.Success) {
      dispatch(userSignInRequest({ username: username, password: password }))

      dispatch(enterpriseNewResponse({ type: EActionStatus.Waiting }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enterpriseNewStatus])

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Input name="name" placeholder="Seu Nome" />
        <Input
          name="enterprise"
          onChange={e => setUsername(`admin@${e.target.value.toLowerCase()}`)}
          placeholder="Nome da Empresa"
        />
        <Input type="email" name="email" placeholder="Seu e-mail" />

        <hr />
        <strong>Não esqueça Seu Usuário:</strong>
        <Input type="usuário" disabled name="username" value={username} placeholder="usuário" />
        <Input type="password" name="password" placeholder="Sua senha" onChange={e => setPassword(e.target.value)} />
        <button type="submit">Criar Empresa</button>
      </Form>
    </Container>
  )
}
