import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import { CategoryService } from 'services/CategoryService'
import history from 'services/history'
import {
  categoryNewRequest,
  categoryNewResponse,
  categoryRequest,
  categoryResponse,
} from 'store/Category/CategoryCreators'
import { ECategoryTypes } from 'store/Category/CategoryCreators.types'
import { EActionStatus } from 'types/Application.types'
import { ICategory } from 'types/Category.types'

export function* categoryRequestSaga(_action: ReturnType<typeof categoryRequest>) {
  try {
    const { getCategories } = new CategoryService()
    const response: AxiosResponse<ICategory[]> = yield getCategories()

    yield put(categoryResponse(response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('Falha ao carregar categorias')
    }
  }
}

export function* categoryNewRequestSaga(action: ReturnType<typeof categoryNewRequest>) {
  try {
    const { category } = action.payload
    const { postCategory } = new CategoryService()
    yield postCategory(category)

    toast.success('Categoria cadastrada com sucesso!')
    yield put(categoryNewResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(categoryNewResponse({ type: EActionStatus.Failure }))
      toast.error('Falha ao criar categoria')
    }
  }
}

export default function* () {
  yield takeLatest(ECategoryTypes.CATEGORY_REQUEST, categoryRequestSaga)
  yield takeLatest(ECategoryTypes.CATEGORY_NEW_REQUEST, categoryNewRequestSaga)
}
