import produce from 'immer'
import { Reducer } from 'redux'
import { ECashTypes, ICashState } from 'store/Cash/CashCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { ICash } from 'types/Cash.types'

const CASH_INITIAL_STATE: ICashState = {}

const CashReducer: Reducer<ICashState> = (state = CASH_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ECashTypes.CASH_REQUEST: {
        draft.cash = {
          status: {
            type: EActionStatus.Busy,
          },
        }

        return draft
      }

      case ECashTypes.CASH_RESPONSE: {
        const {
          payload: { actionStatus, cash },
        } = action as ReturnType<typeof cashResponse>

        draft.cash = {
          status: actionStatus,
          data: cash,
        }

        return draft
      }

      case ECashTypes.CASH_LAST_REQUEST: {
        draft.cashLast = {
          status: {
            type: EActionStatus.Busy,
          },
        }

        return draft
      }

      case ECashTypes.CASH_LAST_RESPONSE: {
        const {
          payload: { actionStatus, cashLast },
        } = action as ReturnType<typeof cashLastResponse>

        draft.cashLast = {
          status: actionStatus,
          data: cashLast ?? draft.cashLast?.data,
        }

        return draft
      }

      case ECashTypes.CASH_NEW_REQUEST: {
        draft.cashNewStatus = {
          type: EActionStatus.Busy,
        }

        return draft
      }

      case ECashTypes.CASH_NEW_RESPONSE: {
        const {
          payload: { actionStatus },
        } = action as ReturnType<typeof cashNewResponse>

        draft.cashNewStatus = actionStatus

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default CashReducer

export const cashRequest = (startDate: Date, endDate: Date) => ({
  type: ECashTypes.CASH_REQUEST,
  payload: { startDate, endDate },
})

export const cashResponse = (actionStatus: IActionStatus, cash?: ICash[]) => ({
  type: ECashTypes.CASH_RESPONSE,
  payload: { actionStatus, cash },
})

export const cashLastRequest = () => ({
  type: ECashTypes.CASH_LAST_REQUEST,
})

export const cashLastResponse = (actionStatus: IActionStatus, cashLast?: ICash[]) => ({
  type: ECashTypes.CASH_LAST_RESPONSE,
  payload: { actionStatus, cashLast },
})

export const cashNewRequest = (cashNew: ICash) => ({
  type: ECashTypes.CASH_NEW_REQUEST,
  payload: { cashNew },
})

export const cashNewResponse = (actionStatus: IActionStatus) => ({
  type: ECashTypes.CASH_NEW_RESPONSE,
  payload: { actionStatus },
})
