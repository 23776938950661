import produce from 'immer'
import { Reducer } from 'redux'
import { EPaymentTypes, IPaymentState } from 'store/Payment/PaymentCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { IPayment } from 'types/Payment.types'

const PAYMENT_INITIAL_STATE: IPaymentState = {}

const PaymentReducer: Reducer<IPaymentState> = (state = PAYMENT_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EPaymentTypes.PAYMENT_REQUEST: {
        draft.payments = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case EPaymentTypes.PAYMENT_RESPONSE: {
        const {
          payload: { actionStatus, payments },
        } = action as ReturnType<typeof paymentResponse>

        draft.payments = {
          status: actionStatus,
          data: payments,
        }

        return draft
      }

      case EPaymentTypes.PAYMENT_NEW_REQUEST: {
        draft.paymentNewStatus = { type: EActionStatus.Busy }

        return draft
      }

      case EPaymentTypes.PAYMENT_NEW_RESPONSE: {
        const {
          payload: { actionStatus },
        } = action as ReturnType<typeof paymentNewResponse>

        draft.paymentNewStatus = actionStatus

        return draft
      }

      case EPaymentTypes.PAYMENT_EDIT_REQUEST: {
        draft.paymentEdit = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case EPaymentTypes.PAYMENT_EDIT_RESPONSE: {
        const {
          payload: { actionStatus, paymentEdit },
        } = action as ReturnType<typeof paymentEditResponse>

        draft.paymentEdit = {
          status: actionStatus,
          data: paymentEdit ?? draft.paymentEdit?.data,
        }

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default PaymentReducer

export const paymentRequest = (startDate: Date, endDate: Date) => ({
  type: EPaymentTypes.PAYMENT_REQUEST,
  payload: { startDate, endDate },
})

export const paymentResponse = (actionStatus: IActionStatus, payments?: IPayment[]) => ({
  type: EPaymentTypes.PAYMENT_RESPONSE,
  payload: { actionStatus, payments },
})

export const paymentNewRequest = (payment: IPayment) => ({
  type: EPaymentTypes.PAYMENT_NEW_REQUEST,
  payload: { payment },
})

export const paymentNewResponse = (actionStatus: IActionStatus) => ({
  type: EPaymentTypes.PAYMENT_NEW_RESPONSE,
  payload: { actionStatus },
})

export const paymentEditRequest = (paymentEdit: IPayment) => ({
  type: EPaymentTypes.PAYMENT_EDIT_REQUEST,
  payload: { paymentEdit },
})

export const paymentEditResponse = (actionStatus: IActionStatus, paymentEdit?: IPayment) => ({
  type: EPaymentTypes.PAYMENT_EDIT_RESPONSE,
  payload: { actionStatus, paymentEdit },
})
