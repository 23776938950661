import { createSelector } from 'reselect'
import combineReducers from 'store/ReducerConfig'

export const selectSaleState = (state: ReturnType<typeof combineReducers>) => {
  return state.sale
}

export const selectSales = createSelector([selectSaleState], ({ sales }) => sales || [])

export const selectEncalhes = createSelector([selectSaleState], ({ encalhes }) => encalhes || [])

export const selectInventarios = createSelector([selectSaleState], ({ inventarios }) => inventarios || [])

export const selectEntradas = createSelector([selectSaleState], ({ entradas }) => entradas || [])

export const selectSaleEdit = createSelector([selectSaleState], ({ saleEdit }) => saleEdit)

export const selectSaleNew = createSelector([selectSaleState], ({ saleNew }) => saleNew)

export const selectSaleCharts = createSelector([selectSaleState], ({ saleCharts }) => saleCharts || [])
