/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo, useEffect } from 'react'
import { FaBarcode } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// import { Input } from '@rocketseat/unform'
import { Grid, Input } from '@mui/material'

import { format, subDays, addDays, startOfDay, endOfDay } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { saleEditSave, saleSearchRequest } from 'store/Sale/SaleCreators'
import { selectSaleNew, selectSales } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container } from 'screens/Layout/styles'
import history from '~/services/history'
import { formatPrice } from '~/util/format'
import { BarcodeScannerComponentTeste } from 'components2/BarCodeScanner/BarCodeScannerTeste'
import { SaleListComponent } from 'components2/SaleList/SaleListComponent'

import { PageDateSelectorComponent } from 'components2/PageDateSeletor/PageDateSelector'

export default function SalesScreen() {
  const dispatch = useDispatch()
  const sales = useSelector(selectSales)
  const saleNew = useSelector(selectSaleNew)
  const [date, setDate] = useState(new Date())
  const profile = useSelector(selectUserProfile)
  const [bar_code, setBar_code] = useState()
  const [total_sales, setTotal_sales] = useState(0)
  const [show_date, setShow_date] = useState(true)
  const [BarCodeScanning, setBarCodeScanning] = useState(false)
  const [results, setResults] = useState([])
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false)
  const dateFormatted = useMemo(() => format(date, "d 'de' MMMM", { locale: pt }), [date])

  useEffect(() => {
    const date1 = startOfDay(date)
    const date2 = endOfDay(date)

    if (!bar_code) setShow_date(true)
    if (bar_code) setShow_date(false)

    dispatch(
      saleSearchRequest({
        date1,
        date2,
        bar_code,
      })
    )
  }, [saleNew, date, profile.company, bar_code, dispatch])

  useEffect(() => {
    if (sales?.status?.type === EActionStatus.Success) {
      setTotal_sales(sales?.data?.reduce((x, y) => x + y.total_price, 0))
    }
  }, [sales])

  function handlePrevDay() {
    setBar_code('')
    setDate(subDays(date, 1))
  }
  function handleNextDay() {
    setBar_code('')
    setDate(addDays(date, 1))
  }

  async function handleEdit(data) {
    dispatch(saleEditSave(data))
    history.push('/editsale')
  }

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0])
        setBarCodeScanning(false)
        setShowBarcodeScanner(false)

        setResults([])
      }
    }
    loadProduct()
  }, [results])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner)
    setBarCodeScanning(!BarCodeScanning)
  }

  const [getcamid, setGetcamid] = useState()
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile?.cam_id) {
          setGetcamid(mediaDevice.deviceId)
        }
      })
    }
    loadCam_id()
    //eslint-disable-next-line
  }, [profile?.cam_id])

  return (
    <Container>
      <PageDateSelectorComponent
        dateFormatted={dateFormatted}
        date={date}
        handlePrevDay={() => handlePrevDay()}
        handleNextDay={() => handleNextDay()}
        show={show_date}
        total_sales={formatPrice(total_sales)}
      />

      <Grid container display="flex" flexDirection="row">
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Input
            autoComplete="off"
            fullWidth
            placeholder="Pesquisar"
            name="bar_code"
            value={bar_code}
            onChange={e => setBar_code(e.target.value)}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FaBarcode key={4} cursor="pointer" onClick={() => handleBarcode()} size={30} color="#FFF" />
        </Grid>
      </Grid>

      <BarcodeScannerComponentTeste
        BarCodeScanning={showBarcodeScanner}
        handleResults={result => setResults([...results, result])}
        getcamid={getcamid}
      />

      <SaleListComponent sales={sales?.data} handleClick={sale => handleEdit(sale)} />
    </Container>
  )
}
