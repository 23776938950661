import produce from 'immer'
import { Reducer } from 'redux'
import { EEnterpriseTypes, IEnterpriseState } from 'store/Enterprise/EnterpriseCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { IDebt, IEnterprise } from 'types/Enterprise.types'

const ENTERPRISE_INITIAL_STATE: IEnterpriseState = {}

const EnterpriseReducer: Reducer<IEnterpriseState> = (state = ENTERPRISE_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EEnterpriseTypes.ENTERPRISE_ENTERPRISES_RESPONSE: {
        const {
          payload: { enterprises },
        } = action as ReturnType<typeof enterpriseEnterprisesResponse>

        draft.enterprises = enterprises

        return draft
      }

      case EEnterpriseTypes.ENTERPRISE_DEBTS_REQUEST: {
        draft.debts = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case EEnterpriseTypes.ENTERPRISE_DEBTS_RESPONSE: {
        const {
          payload: { actionStatus, debts },
        } = action as ReturnType<typeof enterpriseDebtsResponse>

        draft.debts = {
          status: actionStatus,
          data: debts ?? draft.debts?.data,
        }

        return draft
      }

      case EEnterpriseTypes.ENTERPRISE_NEW_REQUEST: {
        draft.enterpriseNewStatus = { type: EActionStatus.Busy }

        return draft
      }

      case EEnterpriseTypes.ENTERPRISE_NEW_RESPONSE: {
        const {
          payload: { actionStatus },
        } = action as ReturnType<typeof enterpriseNewResponse>

        draft.enterpriseNewStatus = actionStatus

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default EnterpriseReducer

export const enterpriseEnterprisesRequest = () => ({
  type: EEnterpriseTypes.ENTERPRISE_ENTERPRISES_REQUEST,
})

export const enterpriseEnterprisesResponse = (enterprises: IEnterprise[]) => ({
  type: EEnterpriseTypes.ENTERPRISE_ENTERPRISES_RESPONSE,
  payload: { enterprises },
})

export const enterpriseDebtsRequest = () => ({
  type: EEnterpriseTypes.ENTERPRISE_DEBTS_REQUEST,
})

export const enterpriseDebtsResponse = (actionStatus: IActionStatus, debts?: IDebt[]) => ({
  type: EEnterpriseTypes.ENTERPRISE_DEBTS_RESPONSE,
  payload: { actionStatus, debts },
})

export const enterpriseNewRequest = (enterprise: IEnterprise) => ({
  type: EEnterpriseTypes.ENTERPRISE_NEW_REQUEST,
  payload: { enterprise },
})

export const enterpriseNewResponse = (actionStatus: IActionStatus) => ({
  type: EEnterpriseTypes.ENTERPRISE_NEW_RESPONSE,
  payload: { actionStatus },
})
