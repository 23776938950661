import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import history from 'services/history'
import { SaleService } from 'services/SaleService'
import {
  saleChartRequest,
  saleChartResponse,
  saleEditRequest,
  saleEditResponse,
  saleEncalhesRequest,
  saleEncalhesResponse,
  saleEntradasRequest,
  saleEntradasResponse,
  saleInventariosRequest,
  saleInventariosResponse,
  saleNewRequest,
  saleNewResponse,
  saleSearchRequest,
  saleSearchResponse,
} from 'store/Sale/SaleCreators'
import { ESaleTypes } from 'store/Sale/SaleCreators.types'
import { EActionStatus } from 'types/Application.types'
import { ISale } from 'types/Sale.types'

export function* saleSearchRequestSaga(action: ReturnType<typeof saleSearchRequest>) {
  try {
    const { saleSearch } = action.payload
    const { getSales, getSales3 } = new SaleService()

    let response: AxiosResponse<ISale[]>

    if (saleSearch?.bar_code && saleSearch?.bar_code?.length >= 2) {
      response = yield getSales3(saleSearch)
    } else {
      response = yield getSales(saleSearch)
    }

    yield put(saleSearchResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleSearchResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar vendas')
    }
  }
}

export function* saleEncalhesRequestSaga(action: ReturnType<typeof saleEncalhesRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getEncalhes } = new SaleService()
    const response: AxiosResponse<ISale[]> = yield getEncalhes(startDate, endDate)

    yield put(saleEncalhesResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleEncalhesResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar encalhes')
    }
  }
}

export function* saleInventariosRequestSaga(action: ReturnType<typeof saleInventariosRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getInventarios } = new SaleService()
    const response: AxiosResponse<ISale[]> = yield getInventarios(startDate, endDate)

    yield put(saleInventariosResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleInventariosResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar inventário')
    }
  }
}

export function* saleEntradasRequestSaga(action: ReturnType<typeof saleEntradasRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getEntradas } = new SaleService()
    const response: AxiosResponse<ISale[]> = yield getEntradas(startDate, endDate)

    yield put(saleEntradasResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleEntradasResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar entradas')
    }
  }
}

export function* saleEditRequestSaga(action: ReturnType<typeof saleEditRequest>) {
  try {
    const { saleEdit } = action.payload
    const { putSale } = new SaleService()
    const response: AxiosResponse<ISale> = yield putSale(saleEdit, saleEdit.id)

    toast.success('Venda atualizada com sucesso')
    yield put(saleEditResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleEditResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha editar venda')
    }
  }
}

export function* saleNewRequestSaga(action: ReturnType<typeof saleNewRequest>) {
  try {
    const { saleNew } = action.payload
    const { postSale } = new SaleService()
    const response: AxiosResponse<ISale> = yield postSale(saleNew)

    toast.success('Venda criada com sucesso')
    yield put(saleNewResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleNewResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao criar venda')
    }
  }
}

export function* saleChartRequestSaga(action: ReturnType<typeof saleChartRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getChart } = new SaleService()
    const response: AxiosResponse<ISale[]> = yield getChart(startDate, endDate)

    yield put(saleChartResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(saleChartResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao gáficos')
    }
  }
}

export default function* () {
  yield takeLatest(ESaleTypes.SALE_SEARCH_REQUEST, saleSearchRequestSaga)
  yield takeLatest(ESaleTypes.SALE_ENCALHE_REQUEST, saleEncalhesRequestSaga)
  yield takeLatest(ESaleTypes.SALE_INVENTARIO_REQUEST, saleInventariosRequestSaga)
  yield takeLatest(ESaleTypes.SALE_ENTRADA_REQUEST, saleEntradasRequestSaga)
  yield takeLatest(ESaleTypes.SALE_EDIT_REQUEST, saleEditRequestSaga)
  yield takeLatest(ESaleTypes.SALE_NEW_REQUEST, saleNewRequestSaga)
  yield takeLatest(ESaleTypes.SALE_CHART_REQUEST, saleChartRequestSaga)
}
