import produce from 'immer'
import { Reducer } from 'redux'
import { IUserSignInResponse } from 'services/UserService'
import { EUserTypes, IUserState } from 'store/User/UserCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { IUserProfile, IUserSignIn } from 'types/User.types'

const USER_INITIAL_STATE: IUserState = {}

const UserReducer: Reducer<IUserState> = (state = USER_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EUserTypes.USER_SIGN_IN_REQUEST: {
        draft.signInStatus = {
          type: EActionStatus.Busy,
          message: null,
        }

        return draft
      }

      case EUserTypes.USER_SIGN_IN_RESPONSE: {
        const {
          payload: { userSignIn, userSignInStatus },
        } = action as ReturnType<typeof userSignInResponse>

        if (userSignIn) {
          draft.profile = userSignIn.user

          draft.auth = {
            signed: true,
            token: userSignIn.token,
            tokenexpires: userSignIn.tokenexpires,
          }
        }

        draft.signInStatus = userSignInStatus

        return draft
      }

      case EUserTypes.USER_PROFILE_UPDATE_REQUEST: {
        draft.profileUpdateStatus = {
          type: EActionStatus.Busy,
          message: null,
        }

        return draft
      }

      case EUserTypes.USER_PROFILE_UPDATE_RESPONSE: {
        const {
          payload: { userProfileUpdateStatus, userProfile },
        } = action as ReturnType<typeof userProfileUpdateResponse>

        if (userProfile) {
          draft.profile = userProfile
        }

        draft.profileUpdateStatus = userProfileUpdateStatus

        return draft
      }

      case EUserTypes.USER_PROFILE_CREATE_REQUEST: {
        draft.profileCreateStatus = {
          type: EActionStatus.Busy,
          message: null,
        }

        return draft
      }

      case EUserTypes.USER_PROFILE_CREATE_RESPONSE: {
        const {
          payload: { userProfileCreateStatus },
        } = action as ReturnType<typeof userProfileCreateResponse>

        draft.profileCreateStatus = userProfileCreateStatus

        return draft
      }

      case EUserTypes.USER_USERS_REQUEST: {
        draft.users = {
          status: {
            type: EActionStatus.Busy,
          },
        }

        return draft
      }

      case EUserTypes.USER_USERS_RESPONSE: {
        const {
          payload: { userUserStatus, usersProfile },
        } = action as ReturnType<typeof userUsersResponse>

        draft.users = {
          data: usersProfile,
          status: userUserStatus,
        }

        return draft
      }

      case EUserTypes.USER_SIGN_OUT_REQUEST: {
        draft.signInStatus = {
          type: EActionStatus.Waiting,
        }

        delete draft.profile
        delete draft.auth

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default UserReducer

export const userSignInRequest = (userSignIn: IUserSignIn) => ({
  type: EUserTypes.USER_SIGN_IN_REQUEST,
  payload: { userSignIn },
})

export const userSignInResponse = (userSignInStatus: IActionStatus, userSignIn?: IUserSignInResponse) => ({
  type: EUserTypes.USER_SIGN_IN_RESPONSE,
  payload: { userSignInStatus, userSignIn },
})

export const userProfileUpdateRequest = (userProfile: IUserProfile) => ({
  type: EUserTypes.USER_PROFILE_UPDATE_REQUEST,
  payload: { userProfile },
})

export const userProfileUpdateResponse = (userProfileUpdateStatus: IActionStatus, userProfile?: IUserProfile) => ({
  type: EUserTypes.USER_PROFILE_UPDATE_RESPONSE,
  payload: { userProfileUpdateStatus, userProfile },
})

export const userProfileCreateRequest = (userProfile: Omit<IUserProfile, 'id'>) => ({
  type: EUserTypes.USER_PROFILE_CREATE_REQUEST,
  payload: { userProfile },
})

export const userProfileCreateResponse = (userProfileCreateStatus: IActionStatus) => ({
  type: EUserTypes.USER_PROFILE_CREATE_RESPONSE,
  payload: { userProfileCreateStatus },
})

export const userUsersRequest = () => ({
  type: EUserTypes.USER_USERS_REQUEST,
})

export const userUsersResponse = (userUserStatus: IActionStatus, usersProfile?: IUserProfile[]) => ({
  type: EUserTypes.USER_USERS_RESPONSE,
  payload: { userUserStatus, usersProfile },
})

export const userSignOutRequest = () => ({
  type: EUserTypes.USER_SIGN_OUT_REQUEST,
})

export const userUpdateToken = () => ({
  type: EUserTypes.USERS_UPDATE_TOKEN,
})
