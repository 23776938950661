import React, { useMemo, useState, useEffect } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Form, Input, Check, Select } from '@rocketseat/unform'

import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { cashLastRequest, cashLastResponse } from 'store/Cash/CashCreators'
import { selectCashLast } from 'store/Cash/CashSelectors'
import { categoryRequest } from 'store/Category/CategoryCreators'
import { selectCategoriesSelect } from 'store/Category/CategorySelectors'
import { productEditStockRequest } from 'store/Product/ProductCreators'
import { saleActualStockRequest, saleEditRequest, saleEditResponse } from 'store/Sale/SaleCreators'
import { selectSaleEdit } from 'store/Sale/SaleSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'

import { Container6, List10, List11, List17 } from 'screens/Layout/styles'
import history from '~/services/history'

export default function SaleEditScreen() {
  const dispatch = useDispatch()
  const cashLast = useSelector(selectCashLast)
  const saleEdit = useSelector(selectSaleEdit)
  const profile = useSelector(selectUserProfile)
  const categoriesSelect = useSelector(selectCategoriesSelect)

  const [data, setData] = useState()
  const optionspgto = [
    { id: 'Dinheiro', title: 'Dinheiro' },
    { id: 'Débito', title: 'Débito' },
    { id: 'Crédito', title: 'Crédito' },
    { id: 'Pix', title: 'Pix' },
  ]
  const [newdate, setNewdate] = useState(saleEdit?.data?.date || new Date())

  useEffect(() => {
    dispatch(categoryRequest())
  }, [dispatch, profile.company])

  useEffect(() => {
    dispatch(saleActualStockRequest(saleEdit?.data?.bar_code))
    //eslint-disable-next-line
  }, [dispatch, saleEdit?.data.bar_code])

  const initialData = useMemo(() => {
    return {
      ...saleEdit?.data,
      price: saleEdit?.data.price.toLocaleString('pt-PT'),
      total_price: saleEdit?.data.total_price.toLocaleString('pt-PT'),
      average_cost: saleEdit?.data.average_cost.toLocaleString('pt-PT'),
    }
    //eslint-disable-next-line
  }, [saleEdit?.data])

  const dateFormatted = useMemo(
    () => format(newdate ? parseISO(newdate) : new Date(), "YYY-MM-dd'T'HH:mm", { locale: pt }),
    [newdate]
  )

  const [categoryvalue, setCategoryvalue] = useState(saleEdit?.data?.category)

  async function handleSubmit(venda) {
    if (!saleEdit?.data?.active && venda.active) {
      return toast.error('Não é posível reativar uma venda')
    }
    setData(venda)

    dispatch(cashLastRequest())
  }

  useEffect(() => {
    if (cashLast?.status?.type === EActionStatus.Success) {
      dispatch(cashLastResponse({ type: EActionStatus.Waiting }))

      const last_cash = format(parseISO(cashLast.data[0].date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt })
      const data_venda = format(parseISO(saleEdit?.data?.date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt })
      if (last_cash > data_venda) {
        return toast.error('Caixa fechado')
      }
      if (last_cash > newdate) {
        return toast.error('Caixa fechado')
      }

      dispatch(
        saleEditRequest({
          ...data,
          date: format(parseISO(newdate), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt }),
          id: saleEdit?.data?.id,
        })
      )

      if (saleEdit?.data?.active !== data.active) {
        const newqty = saleEdit?.data?.active ? -saleEdit?.data?.qty : saleEdit?.data?.qty
        if (saleEdit?.data?.productId) {
          dispatch(productEditStockRequest(saleEdit?.data?.productId, newqty))
        }
      }

      if (saleEdit?.data?.qty !== data.qty && data.active) {
        if (saleEdit?.data?.productId) {
          dispatch(productEditStockRequest(saleEdit?.data?.productId, data.qty - saleEdit?.data?.qty))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cashLast?.status, data])

  useEffect(() => {
    if (saleEdit?.status?.type === EActionStatus.Success) {
      dispatch(saleEditResponse({ type: EActionStatus.Waiting }))

      history.push('/sales')
    }
    //eslint-disable-next-line
  }, [dispatch, saleEdit?.status?.type])

  return (
    <Container6>
      <Form initialData={initialData} onSubmit={handleSubmit}>
        <Link to="/sales">
          <MdChevronLeft size={30} />
        </Link>

        <List10>
          <span>Quantidade</span>
          <span>Usuário</span>
        </List10>
        <List10>
          <Input name="qty" placeholder="Quantidade" />
          <Input name="seller" disabled placeholder="Usuário" />
        </List10>

        <span>Produto</span>
        <Input name="name" placeholder="Nome do Produto" />

        <List10>
          <span>Categoria</span>
          <span>Forma de Pagamento</span>
        </List10>
        <List10>
          <Select
            name="category"
            placeholder="Categoria do Produto"
            onChange={e => setCategoryvalue(e.target.value)}
            value={categoryvalue}
            options={categoriesSelect}
          />
          {saleEdit?.pgto2 ? (
            <Input name="pgto2" disabled value={`${saleEdit.pgto} / ${saleEdit.pgto2}`} />
          ) : (
            <Select name="pgto" placeholder="Forma de Pagamento" options={optionspgto} />
          )}
        </List10>

        <List17>
          <span>Preço</span>
          <span>Desconto</span>
          {profile?.level ? null : <span>Custo</span>}
        </List17>
        <List17>
          <Input name="price" placeholder="Preço" width="20px" />
          <Input name="discount" type="number" step="0.01" placeholder="Desconto" width="20px" />
          {profile?.level ? <></> : <Input name="average_cost" placeholder="Custo" />}
        </List17>

        <span>Valor Total</span>
        <Input name="total_price" disabled placeholder="Valor total da Venda" />

        <List10>
          <span>Observação</span>
          <span>Estoque Atual</span>
        </List10>
        <List10>
          <Input name="note" placeholder="Observação" />
          <Input name="atual_stock" placeholder="Estoque Atual" disabled />
        </List10>

        <span>Data</span>
        <Input
          name="date"
          value={dateFormatted}
          onChange={e => setNewdate(e.target.value)}
          type="datetime-local"
          placeholder="Data da Venda"
        />

        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="5px" name="active" />
          </ul>
          <button type="submit">Atualizar Venda</button>
        </List11>
      </Form>
    </Container6>
  )
}
