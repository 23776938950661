import { toast } from 'react-toastify'

import { put, takeLatest } from 'redux-saga/effects'
import { FileService } from 'services/FileService'
import history from 'services/history'
import { fileNewRequest, fileNewResponse } from 'store/File/FileCreators'
import { EFileTypes } from 'store/File/FileCreators.types'
import { EActionStatus } from 'types/Application.types'
import { IFile } from 'types/File.types'
import { AxiosResponse } from 'axios'

export function* fileNewRequestSaga(action: ReturnType<typeof fileNewRequest>) {
  try {
    const { fileNew } = action.payload
    const { postFile } = new FileService()

    const response: AxiosResponse<IFile> = yield postFile(fileNew)

    toast.success('Arquivo enviado com sucesso!')
    yield put(fileNewResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(fileNewResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao enviar arquivo')
    }
  }
}

export default function* () {
  yield takeLatest(EFileTypes.FILE_NEW_REQUEST, fileNewRequestSaga)
}
