import React from 'react'
import { useDispatch } from 'react-redux'

import { userSignOutRequest } from 'store/User/UserCreators'

export default function UserSignOutScreen() {
  const dispatch = useDispatch()
  dispatch(userSignOutRequest())
  return <></>
}
