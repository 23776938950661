import React, { useState, useRef, useEffect } from 'react'
import { useField } from '@rocketseat/unform'
import { Container } from './styles'
import { selectFileNew } from 'store/File/FileSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { fileNewRequest, fileNewResponse } from 'store/File/FileCreators'
import { EActionStatus } from 'types/Application.types'

export default function AvatarInputComponent() {
  const dispatch = useDispatch()
  const fileNew = useSelector(selectFileNew)
  const { defaultValue, registerField } = useField('avatar')

  const [file, setFile] = useState(defaultValue && defaultValue.id)
  const [preview, setPreview] = useState(defaultValue && defaultValue.url)

  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      })
    }
  }, [ref, registerField])

  async function handleChange(e) {
    const data = new FormData()
    data.append('file', e.target.files[0])

    dispatch(fileNewRequest(data))
  }

  useEffect(() => {
    if (fileNew?.status?.type === EActionStatus.Success) {
      dispatch(fileNewResponse({ type: EActionStatus.Waiting }))

      setFile(fileNew?.data.id)
      setPreview(fileNew?.data.url)
    }
    //eslint-disable-next-line
  }, [fileNew?.status?.type])

  return (
    <Container>
      <label htmlFor="avatar">
        <img src={preview || 'https://api.adorable.io/avatars/50/abott@adorable.png'} alt="" />

        <input type="file" id="avatar" accept="image/*" data-file={file} onChange={handleChange} ref={ref} />
      </label>
    </Container>
  )
}
