import api from 'services/api'
import { IPayment } from 'types/Payment.types'

export class PaymentService {
  getPayments = (startDate: Date, endDate: Date) =>
    api.get<IPayment[]>('payments', { params: { date1: startDate, date2: endDate } })

  postPayment = (payment: IPayment) => api.post<IPayment>('payments', payment)

  putPayment = (payment: IPayment) => api.put<IPayment>('payments', payment)
}
