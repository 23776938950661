import api from 'services/api'
import { IDebt, IEnterprise } from 'types/Enterprise.types'

export class EnterpriseService {
  getEnterprises = () => api.get<IEnterprise[]>('enterprise')

  getDebts = () => api.get<IDebt[]>('debt', { params: { active: true } })

  postEnterprise = (enterprise: IEnterprise) => api.post<IEnterprise>('enterprise', enterprise)
}
