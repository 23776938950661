import { EOrderDirection } from 'types/Application.types'
import { IProductSearch } from 'types/Product.types'
import * as Yup from 'yup'

export const orderOptions = [
  { id: 'xprice', title: 'Menor Preço', teste: true },
  { id: 'yprice', title: 'Maior Preço', teste: false },
  { id: 'ylast_sale', title: 'Vendas Novas', teste: true },
  { id: 'xlast_sale', title: 'Vendas Antigas', teste: true },
  { id: 'ystock', title: 'Maior Estoque', teste: true },
  { id: 'xstock', title: 'Menor Estoque', teste: true },
  { id: 'ycreated_at', title: 'Últimos Criados', teste: true },
]

export const defaultProductSearch: IProductSearch = {
  itens_por_pagina: 20,
  page: 1,
  order: 'xprice',
  order2: 'price',
  direction: EOrderDirection.ASC,
  category: 'Ativos',
}

export const productEditSchema = Yup.object().shape({
  bar_code: Yup.string().required('O código é obrigatório'),
  name: Yup.string().required('O Nome é obrigatório'),
  category: Yup.string().required('A categoria é obrigatório'),
  stock: Yup.number(),
  addstock: Yup.number(),
  addstockprice: Yup.number(),
  stockpile: Yup.boolean(),
  active: Yup.boolean(),
  price: Yup.number()
    .required('O preço é obrigatório')
    .transform((_: any, value: any) => +value.replace(/,/, '.'))
    .positive('O preço deve ser positivo'),
  average_cost: Yup.number().transform((_: any, value: any) => +value.replace(/,/, '.')),
})

export const productNewSchema = Yup.object().shape({
  bar_code: Yup.string().required('O código é obrigatório'),
  name: Yup.string().required('O Nome é obrigatório'),
  category: Yup.string().required('A categoria é obrigatório'),
  stock: Yup.number(),
  stockpile: Yup.boolean(),
  price: Yup.number()
    .required('O preço é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O preço deve ser positivo'),
  average_cost: Yup.number().transform((_, value) => +value.replace(/,/, '.')),
})
