import { all, call, spawn } from '@redux-saga/core/effects'
import ProductSagas from 'store/Product/ProductSagas'
import CategorySagas from 'store/Category/CategorySagas'
import UserSagas from 'store/User/UserSagas'
import EnterpriseSagas from 'store/Enterprise/EnterpriseSagas'
import CashSagas from 'store/Cash/CashSagas'
import PaymentSagas from 'store/Payment/PaymentSagas'
import SaleSagas from 'store/Sale/SaleSagas'
import FileSagas from 'store/File/FileSagas'

const sagas = [CashSagas, CategorySagas, EnterpriseSagas, PaymentSagas, ProductSagas, SaleSagas, UserSagas, FileSagas]

export default function* SagaConfig() {
  yield all(
    sagas?.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch {}
        }
      })
    )
  )
}
