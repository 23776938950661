import api from 'services/api'
import { ICash } from 'types/Cash.types'

export class CashService {
  getCashLast = () => api.get<ICash[]>('lastcash')

  getCash = (startDate: Date, endDate: Date) =>
    api.get<ICash[]>('cash', { params: { date1: startDate, date2: endDate } })

  postCash = (cash: ICash) => api.post<ICash[]>('cash', cash)
}
