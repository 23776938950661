import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import { CashService } from 'services/CashService'
import history from 'services/history'
import {
  cashLastRequest,
  cashLastResponse,
  cashNewRequest,
  cashNewResponse,
  cashRequest,
  cashResponse,
} from 'store/Cash/CashCreators'
import { ECashTypes } from 'store/Cash/CashCreators.types'
import { EActionStatus } from 'types/Application.types'
import { ICash } from 'types/Cash.types'

export function* cashRequestSaga(action: ReturnType<typeof cashRequest>) {
  try {
    const { startDate, endDate } = action.payload
    const { getCash } = new CashService()
    const response: AxiosResponse<ICash[]> = yield getCash(startDate, endDate)

    yield put(cashResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(cashResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar caixa')
    }
  }
}

export function* cashLastRequestSaga(_action: ReturnType<typeof cashLastRequest>) {
  try {
    const { getCashLast } = new CashService()
    const response: AxiosResponse<ICash[]> = yield getCashLast()

    yield put(cashLastResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(cashLastResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar último caixa')
    }
  }
}

export function* cashNewRequestSaga(action: ReturnType<typeof cashNewRequest>) {
  try {
    const { cashNew } = action.payload
    const { postCash } = new CashService()
    yield postCash(cashNew)

    toast.success('Caixa fechado com sucesso!')
    yield put(cashNewResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(cashNewResponse({ type: EActionStatus.Failure }))
      toast.error('Falha ao criar caixa')
    }
  }
}

export default function* () {
  yield takeLatest(ECashTypes.CASH_REQUEST, cashRequestSaga)
  yield takeLatest(ECashTypes.CASH_LAST_REQUEST, cashLastRequestSaga)
  yield takeLatest(ECashTypes.CASH_NEW_REQUEST, cashNewRequestSaga)
}
