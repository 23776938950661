import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Input, Select } from '@rocketseat/unform'

import { categoryNewRequest, categoryNewResponse } from 'store/Category/CategoryCreators'
import { selectCategoriesSelect, selectCategoryNewStatus } from 'store/Category/CategorySelectors'
import { selectUserProfile } from 'store/User/UserSelectors'
import { EActionStatus } from 'types/Application.types'
import * as Yup from 'yup'

import { Container6 } from 'screens/Layout/styles'
import history from '~/services/history'

const schema = Yup.object().shape({
  category: Yup.string().required('A nova categoria é obrigatória'),
})

export default function CategoryNewScreen() {
  const dispatch = useDispatch()
  const profile = useSelector(selectUserProfile)

  const categoriesSelect = useSelector(selectCategoriesSelect)
  const categoryNewStatus = useSelector(selectCategoryNewStatus)

  async function handleSubmit(data) {
    dispatch(
      categoryNewRequest({
        ...data,
        enterprise: profile?.enterprise,
      })
    )
  }

  useEffect(() => {
    if (categoryNewStatus?.type === EActionStatus.Success) {
      dispatch(categoryNewResponse({ type: EActionStatus.Waiting }))
      history.push('/newproduct')
    }
  }, [categoryNewStatus, dispatch])

  return (
    <Container6>
      <Form onSubmit={handleSubmit} schema={schema}>
        <Select name="existscategory" placeholder="Categorias Existentes" options={categoriesSelect} />
        <Input name="category" placeholder="Nova Categoria" />
        <button type="submit">Adicionar Categoria</button>
      </Form>
    </Container6>
  )
}
