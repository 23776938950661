import produce from 'immer'
import { Reducer } from 'redux'
import { IProductsResponse } from 'services/ProductService'
import { EProductTypes, IProductState } from 'store/Product/ProductCreators.types'
import { EActionStatus, IActionStatus } from 'types/Application.types'
import { IProduct, IProductSearch, IProductStock } from 'types/Product.types'

const PRODUCT_INITIAL_STATE: IProductState = {}

const ProductReducer: Reducer<IProductState> = (state = PRODUCT_INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EProductTypes.PRODUCT_SEARCH_REQUEST: {
        const {
          payload: { productSearch },
        } = action as ReturnType<typeof productSearchRequest>
        draft.productSearch = productSearch

        return draft
      }

      case EProductTypes.PRODUCT_SEARCH_RESPONSE: {
        const {
          payload: { productsResponse },
        } = action as ReturnType<typeof productSearchResponse>

        draft.products = productsResponse.products

        if (draft.productSearch) {
          draft.productSearch = {
            ...draft.productSearch,
            totalProducts: productsResponse.total_products,
            totalPages: Math.ceil(productsResponse.total_products / draft.productSearch.itens_por_pagina),
          }
        }

        return draft
      }

      case EProductTypes.PRODUCT_EDIT_SAVE: {
        const {
          payload: { productEdit },
        } = action as ReturnType<typeof productEditSave>
        draft.productEdit = productEdit

        return draft
      }

      case EProductTypes.PRODUCT_EDIT_REQUEST: {
        const {
          payload: { productEdit },
        } = action as ReturnType<typeof productEditRequest>
        draft.productEdit = productEdit

        return draft
      }

      case EProductTypes.PRODUCT_NEW_REQUEST: {
        const {
          payload: { productNew },
        } = action as ReturnType<typeof productNewRequest>
        draft.productNew = productNew

        return draft
      }

      case EProductTypes.PRODUCT_STOCK_REQUEST: {
        draft.productStock = {
          status: { type: EActionStatus.Busy },
        }

        return draft
      }

      case EProductTypes.PRODUCT_STOCK_RESPONSE: {
        const {
          payload: { productStockStatus, productStock },
        } = action as ReturnType<typeof productStockResponse>

        draft.productStock = {
          status: productStockStatus,
          data: productStock,
        }

        return draft
      }

      case EProductTypes.PRODUCT_EDIT_STOCK_REQUEST: {
        draft.productEditStockStatus = { type: EActionStatus.Busy }

        return draft
      }

      case EProductTypes.PRODUCT_EDIT_STOCK_RESPONSE: {
        const {
          payload: { productEditStockStatus },
        } = action as ReturnType<typeof productEditStockResponse>

        draft.productEditStockStatus = productEditStockStatus

        return draft
      }

      default: {
        return draft
      }
    }
  })
}

export default ProductReducer

export const productSearchRequest = (productSearch: IProductSearch) => ({
  type: EProductTypes.PRODUCT_SEARCH_REQUEST,
  payload: { productSearch },
})

export const productSearchResponse = (productsResponse: IProductsResponse) => ({
  type: EProductTypes.PRODUCT_SEARCH_RESPONSE,
  payload: { productsResponse },
})

export const productEditSave = (productEdit: IProduct) => ({
  type: EProductTypes.PRODUCT_EDIT_SAVE,
  payload: { productEdit },
})

export const productEditRequest = (productEdit: IProduct) => ({
  type: EProductTypes.PRODUCT_EDIT_REQUEST,
  payload: { productEdit },
})

export const productEditResponse = (productEdit: IProduct) => ({
  type: EProductTypes.PRODUCT_EDIT_RESPONSE,
  payload: { productEdit },
})

export const productNewRequest = (productNew: IProduct) => ({
  type: EProductTypes.PRODUCT_NEW_REQUEST,
  payload: { productNew },
})

export const productNewResponse = (productNew: IProduct) => ({
  type: EProductTypes.PRODUCT_NEW_RESPONSE,
  payload: { productNew },
})

export const productStockRequest = () => ({
  type: EProductTypes.PRODUCT_STOCK_REQUEST,
})

export const productStockResponse = (productStockStatus: IActionStatus, productStock: IProductStock[]) => ({
  type: EProductTypes.PRODUCT_STOCK_RESPONSE,
  payload: { productStockStatus, productStock },
})

export const productEditStockRequest = (id: number, subtract_stock: number) => ({
  type: EProductTypes.PRODUCT_EDIT_STOCK_REQUEST,
  payload: { id, subtract_stock },
})

export const productEditStockResponse = (productEditStockStatus: IActionStatus) => ({
  type: EProductTypes.PRODUCT_EDIT_STOCK_RESPONSE,
  payload: { productEditStockStatus },
})
