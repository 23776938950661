/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'

import PropTypes from 'prop-types'

import history from '~/services/history'
import { store } from 'store/StoreConfig'
import LayoutScreen from 'screens/Layout/LayoutScreen'
import { userUpdateToken } from 'store/User/UserCreators'

export default function RouteWrapper({ component: Component, isPrivate, isLogin, ...rest }) {
  const auth = store.getState().user.auth

  if (!auth?.token && isPrivate) {
    history.push('/')
  }

  if (auth?.token && isLogin) {
    history.push('/sales')
  }

  const layout = auth?.token ? 'private' : 'public'

  const dispatch = useDispatch()
  if (auth?.tokenexpires - new Date().getTime() / 1000 < 3600) {
    if (auth?.tokenexpires - new Date().getTime() / 1000 > 0) {
      dispatch(userUpdateToken())
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        <LayoutScreen layout={layout}>
          <Component {...props} />
        </LayoutScreen>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isLogin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}
RouteWrapper.defaultProps = {
  isPrivate: false,
  isLogin: false,
}
