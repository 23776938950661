import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import { EnterpriseService } from 'services/EnterpriseService'
import history from 'services/history'
import {
  enterpriseDebtsRequest,
  enterpriseDebtsResponse,
  enterpriseEnterprisesRequest,
  enterpriseEnterprisesResponse,
  enterpriseNewRequest,
  enterpriseNewResponse,
} from 'store/Enterprise/EnterpriseCreators'
import { EEnterpriseTypes } from 'store/Enterprise/EnterpriseCreators.types'
import { EActionStatus } from 'types/Application.types'
import { IDebt, IEnterprise } from 'types/Enterprise.types'

export function* enterpriseEnterprisesRequestSaga(_action: ReturnType<typeof enterpriseEnterprisesRequest>) {
  try {
    const { getEnterprises } = new EnterpriseService()
    const response: AxiosResponse<IEnterprise[]> = yield getEnterprises()

    yield put(enterpriseEnterprisesResponse(response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('Falha ao carregar categorias')
    }
  }
}

export function* enterpriseDebtsRequestSaga(_action: ReturnType<typeof enterpriseDebtsRequest>) {
  try {
    const { getDebts } = new EnterpriseService()
    const response: AxiosResponse<IDebt[]> = yield getDebts()

    yield put(enterpriseDebtsResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(enterpriseDebtsResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar categorias')
    }
  }
}

export function* enterpriseNewRequestSaga(action: ReturnType<typeof enterpriseNewRequest>) {
  try {
    const { enterprise } = action.payload
    const { postEnterprise } = new EnterpriseService()
    yield postEnterprise(enterprise)

    toast.success('Empresa cadastrada com sucesso!')
    yield put(enterpriseNewResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(enterpriseDebtsResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao cadastrar empresa')
    }
  }
}

export default function* () {
  yield takeLatest(EEnterpriseTypes.ENTERPRISE_ENTERPRISES_REQUEST, enterpriseEnterprisesRequestSaga)
  yield takeLatest(EEnterpriseTypes.ENTERPRISE_DEBTS_REQUEST, enterpriseDebtsRequestSaga)
  yield takeLatest(EEnterpriseTypes.ENTERPRISE_NEW_REQUEST, enterpriseNewRequestSaga)
}
