import api from 'services/api'
import { IProduct, IProductSearch, IProductStock } from 'types/Product.types'

export class ProductService {
  getProducts = (productSearch: IProductSearch) => api.get<IProductsResponse>('products', { params: productSearch })

  getProducts3 = (productSearch: IProductSearch) => api.get<IProductsResponse>('products3', { params: productSearch })

  getProductStock = (bar_code: string) => api.get<IProductsResponse>('products5', { params: { bar_code } })

  putProduct3 = (productEdit: IProduct, id: number) => api.put('products3', { data: productEdit, id })

  putProductStock = (id: number, subtract_stock: number) => api.put('products4', { id, subtract_stock })

  postProduct = (productNew: IProduct) => api.post('products', productNew)

  getProductsStock = () => api.get<IProductStock[]>('stock')
}

export interface IProductsResponse {
  products: IProduct[]
  total_products: number
}
