import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import api from 'services/api'
import history from 'services/history'
import { IUserSignInResponse, UserService } from 'services/UserService'
import {
  userProfileUpdateRequest,
  userProfileUpdateResponse,
  userSignInRequest,
  userSignInResponse,
  userUsersRequest,
  userUsersResponse,
  userProfileCreateRequest,
  userProfileCreateResponse,
  userUpdateToken,
} from 'store/User/UserCreators'
import { EUserTypes } from 'store/User/UserCreators.types'
import { EActionStatus } from 'types/Application.types'
import { IUserProfile } from 'types/User.types'

export function setToken(persist: any) {
  if (!persist.payload) return
  const { token } = persist.payload.user.auth
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function* userSignInRequestSaga(action: ReturnType<typeof userSignInRequest>) {
  try {
    const userSignIn = action.payload.userSignIn

    const { postLogin } = new UserService()

    const response: AxiosResponse<IUserSignInResponse> = yield postLogin(userSignIn)

    api.defaults.headers.Authorization = `Bearer ${response.data.token}`

    yield put(userSignInResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(userSignInResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha na autenticação')
    }
  }
}

export function* userUsersRequestSaga(_action: ReturnType<typeof userUsersRequest>) {
  try {
    const { getUsers } = new UserService()

    const response: AxiosResponse<IUserProfile[]> = yield getUsers()

    yield put(userUsersResponse({ type: EActionStatus.Success }, response.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(userSignInResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Falha ao carregar usuários')
    }
  }
}

export function* userProfileUpdateRequestSaga(action: ReturnType<typeof userProfileUpdateRequest>) {
  try {
    const userProfileUpdate = action.payload.userProfile

    const { name, cam_id, header, username, id, avatar_id, company, enterprise, avatar, level, ...rest } =
      userProfileUpdate

    const profile = {
      name,
      username,
      avatar_id,
      company,
      enterprise,
      cam_id,
      header,
      user_id: id,
      id,
      avatar,
      level,
      ...(rest.oldPassword ? rest : {}),
    }

    const { putUser, postProfileUpdate } = new UserService()

    const responseUser: AxiosResponse<IUserProfile> = yield putUser(profile)

    const responseProfile: AxiosResponse<IUserSignInResponse> = yield postProfileUpdate()

    toast.success('Perfil atualizado com sucesso!')

    api.defaults.headers.Authorization = `Bearer ${responseProfile.data.token}`

    yield put(userProfileUpdateResponse({ type: EActionStatus.Success }, responseUser.data))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(userProfileUpdateResponse({ type: EActionStatus.Failure }, undefined))
      toast.error('Erro ao atualizar Perfil')
    }
  }
}

export function* userProfileCreateRequestSaga(action: ReturnType<typeof userProfileCreateRequest>) {
  try {
    const userProfileCreate = action.payload.userProfile

    const { postUser } = new UserService()

    yield postUser(userProfileCreate)

    toast.success('Usuário cadastrado com sucesso')

    yield put(userProfileCreateResponse({ type: EActionStatus.Success }))
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      yield put(userProfileCreateResponse({ type: EActionStatus.Failure }))
      toast.error('Falha ao cadastrar Usuário')
    }
  }
}

export function* userUpdateTokenSaga(_action: ReturnType<typeof userUpdateToken>) {
  try {
    const { postProfileUpdate } = new UserService()

    const responseProfile: AxiosResponse<IUserSignInResponse> = yield postProfileUpdate()

    api.defaults.headers.Authorization = `Bearer ${responseProfile.data.token}`
  } catch (err: any) {
    if (err.response) {
      toast.error(err.response.data.error)
      if (err.response.data.error === 'Token invalid') {
        history.push('/signout')
      }
    } else {
      toast.error('Erro ao atualizar Token')
    }
  }
}

export default function* () {
  yield takeLatest('persist/REHYDRATE', setToken)
  yield takeLatest(EUserTypes.USER_SIGN_IN_REQUEST, userSignInRequestSaga)
  yield takeLatest(EUserTypes.USER_PROFILE_UPDATE_REQUEST, userProfileUpdateRequestSaga)
  yield takeLatest(EUserTypes.USER_PROFILE_CREATE_REQUEST, userProfileCreateRequestSaga)
  yield takeLatest(EUserTypes.USER_USERS_REQUEST, userUsersRequestSaga)
  yield takeLatest(EUserTypes.USERS_UPDATE_TOKEN, userUpdateTokenSaga)
}
