import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { enterpriseDebtsRequest, enterpriseEnterprisesRequest } from 'store/Enterprise/EnterpriseCreators'
import { selectDebts, selectEnterprises } from 'store/Enterprise/EnterpriseSelectors'
import { selectUserProfile } from 'store/User/UserSelectors'

import { Grid, List, List63, List20, Container } from 'screens/Layout/styles'
import history from '~/services/history'
import { formatPrice } from '~/util/format'

export default function ConfigScreen() {
  const dispatch = useDispatch()

  const profile = useSelector(selectUserProfile)
  const enterprises = useSelector(selectEnterprises)
  const debts = useSelector(selectDebts)

  useEffect(() => {
    dispatch(enterpriseEnterprisesRequest())
    dispatch(enterpriseDebtsRequest())
  }, [dispatch])

  return (
    <Container>
      {profile?.level ? (
        <></>
      ) : (
        <>
          <strong>Saldo restantes em conta: {formatPrice(enterprises[0]?.balance)}</strong>
          <strong>
            Valor Atual do plano: {formatPrice(enterprises[0]?.plan)}
            /mês
          </strong>
          <Grid columns="2">
            <List>
              <button type="button" onClick={() => history.push('/deposit')}>
                Faça um depósito
              </button>
            </List>
            <List>
              <button type="button" onClick={() => history.push('/register')}>
                Criar Vendedor
              </button>
            </List>

            <List>
              <button type="button" onClick={() => history.push('/users')}>
                Lista de Usuários
              </button>
            </List>
          </Grid>

          {debts?.data?.length === 0 ? null : (
            <>
              <strong>Lançamentos:</strong>
              <List20>
                <List63 key="Data">
                  <strong>Período</strong>
                  <strong>Valor</strong>
                </List63>
              </List20>
              <header />
            </>
          )}

          <List20>
            {!debts?.data
              ? null
              : debts?.data?.map(debt => (
                  <List63 key={debt.id}>
                    <span>
                      {format(parseISO(debt.first_period), "dd'/'MM'", { locale: pt })}-{' '}
                      {format(parseISO(debt.end_period), "dd'/'MM'", { locale: pt })}
                    </span>
                    <span>{formatPrice(debt.price)}</span>
                  </List63>
                ))}
          </List20>
        </>
      )}
    </Container>
  )
}
