import React, { useMemo } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { addDays, isAfter } from 'date-fns'
import { Grid, Typography, Button } from '@mui/material'

export interface IPageDateSelectorProps {
  handlePrevDay: () => void
  handleNextDay: () => void
  dateFormatted: string
  date: Date
  show: boolean
  total_sales: string
}

const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export const PageDateSelectorComponent: React.FC<IPageDateSelectorProps> = ({
  dateFormatted,
  date,
  handlePrevDay,
  handleNextDay,
  show = true,
  total_sales,
}) => {
  const showright = useMemo(() => isAfter(new Date(), addDays(date, 1)), [date])

  return !show ? (
    <></>
  ) : (
    <Grid container display="flex" flexDirection="row">
      <Grid item lg={1} md={1} sm={1} xs={1}>
        <Button style={{ padding: 0, margin: 0 }} onClick={handlePrevDay}>
          <MdChevronLeft style={{ padding: 0, margin: 0 }} size={30} color="#ffff" />
        </Button>
      </Grid>
      <Grid item textAlign="center" lg={8} md={8} sm={8} xs={8}>
        <Typography>{`${dateFormatted} (${days[date.getDay()]})`}</Typography>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}>
        <Button style={{ padding: 0, margin: 0 }} disabled={!showright} onClick={handleNextDay}>
          <MdChevronRight style={{ padding: 0, margin: 0 }} size={30} color={showright ? '#ffff' : 'gray'} />
        </Button>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <Typography>{total_sales}</Typography>
      </Grid>
    </Grid>
  )
}
